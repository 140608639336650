import { BaseAddress } from 'shop/types/cart'
import { isEmpty } from 'lodash'

type AddressParams = {
  line_1: string | null
  line_2: string | null
  city: string | null
  zip: string | null
}

/** Returns user's address as string to render in the UI */
export const createAddressString = (
  address: AddressParams | BaseAddress | null | undefined,
  formatOptions: { withCommas: boolean } = { withCommas: false }
): string => {
  if (!address || isEmpty(address)) {
    return ''
  }

  let line1
  let line2

  // If address is from the hasura api
  if ('line_1' in address) {
    line2 = address.line_2
    line1 = address.line_1
  }

  // If address is from the consumer api
  if ('lineOne' in address) {
    line2 = address.lineTwo
    line1 = address.lineOne
  }

  const city = address.city
  const zip = address.zip

  if (!!formatOptions?.withCommas) {
    return (
      ((line2 && `${line2}, `) || ``) +
      ((line1 && `${line1}, `) || ``) +
      ((city && `${city}, `) || ``) +
      ((zip && `${zip}`) || ``)
    )
  }

  return (
    ((line2 && `${line2} `) || ``) +
    ((line1 && `${line1} `) || ``) +
    ((city && `${city} `) || ``) +
    ((zip && `${zip}`) || ``)
  )
}
