import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useShop } from 'shop/hooks'
import { ExtendedOrderItem, OrderItem } from 'shop/types/cart'
import { formatMoney } from '../Cart/utils'
import { getImageURL } from 'shop/utils/common'

type Props = {
  item: OrderItem | ExtendedOrderItem
  showPrice?: boolean
}

const ProductInfoItem = ({ item, showPrice = false }: Props) => {
  const { config } = useShop()
  const { domain, assetHost } = config

  const renderPrices = () => {
    const extendedItem = item as ExtendedOrderItem
    return (
      <ItemPrice>
        <Price>
          {formatMoney(parseFloat(extendedItem.oldPriceWithVat))}
          <GreyText>Old Price</GreyText>
        </Price>
        <Price>
          {formatMoney(parseFloat(extendedItem.newPriceWithVat))}
          <GreyText>New Price</GreyText>
        </Price>
      </ItemPrice>
    )
  }

  return (
    <Item>
      <ProductImage
        backgroundImage={getImageURL(domain, assetHost, item.variantId)}
      />
      <ItemInfo>
        {item.quantity > 1 && `${item.quantity} `}
        {item.product.name}
      </ItemInfo>
      {showPrice && renderPrices()}
    </Item>
  )
}

const Item = styled.li<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 0',

  '&:first-of-type': {
    paddingTop: '0'
  },
  '&:last-of-type': {
    paddingBottom: '0'
  },
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #F0F0F0'
  }
}))

const ItemInfo = styled.span<StyledHTMLElement>(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '10px',
  fontWeight: 500,
  flexGrow: 1
}))

const ItemPrice = styled.div<StyledHTMLElement>(() => ({
  marginLeft: 'auto',
  alignItems: 'center',
  gap: '16px',
  display: 'flex'
}))

const Price = styled.div<StyledHTMLElement>(() => ({
  fontSize: '16px',
  fontWeight: 500
}))

const GreyText = styled.div<StyledHTMLElement>(() => ({
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  color: '#8C8C8C',
  fontWeight: 400
}))

const ProductImage = styled.div<
  StyledHTMLElement & { backgroundImage: string },
  Required<Theme>
>(({ theme, backgroundImage }) => ({
  background: `lightgray url(${backgroundImage})`,
  alignSelf: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  marginRight: '12px',
  borderRadius: '8px',
  flex: '0 0 30px',
  height: '30px',
  [theme['mediaQueries']['viewport6']]: {
    flex: '0 0 40px',
    height: '40px'
  },
  [theme['mediaQueries']['viewport7']]: {
    flex: '0 0 45px',
    height: '45px'
  }
}))

export default ProductInfoItem
