import styled from '@emotion/styled'
import { formatMoney, sanitizeNumber } from '../Cart/utils'
import { Button } from 'shop/components'
import { useConsumerCart } from 'shop/hooks'

interface Props {
  cartTotal: string
  onClick: () => void
}

const StickyCartTotalMobileFooter = ({ cartTotal, onClick }: Props) => {
  const { cartLoading } = useConsumerCart()

  const total = sanitizeNumber(cartTotal)
  return (
    <StickyMobileFooter data-testid='cart-total-sticky-mobile-footer'>
      <Button type='button' onClick={onClick} isLoading={cartLoading}>
        View basket {formatMoney(total)}
      </Button>
    </StickyMobileFooter>
  )
}

const StickyMobileFooter = styled.div(({ theme }: any) => ({
  zIndex: 20, // same as NavBar
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  backgroundColor: 'white',
  boxShadow: '0 -1px 8px 8px rgba(0, 0, 0, 0.05)',

  [theme.mediaQueries.viewport7]: {
    display: 'none'
  }
}))

export default StickyCartTotalMobileFooter
