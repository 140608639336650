import { CustomerDetailsParams, DomesticAddress } from 'shop/types/cart'

export type FormFields = {
  customerDetails: {
    firstName: string
    lastName: string
    email: string
    contactNumber: string
    contactNumPrefix: string
  }
  recipientDetails: {
    firstName: string
    lastName: string
    contactNumber: string
    contactNumPrefix: string
  }
  forSomeoneElse: boolean
  orderNotes: string
  orderNotesEnabled: boolean
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  marketingOptIn: boolean
  customFieldValue: string
  saveAddress: boolean
}

export interface CheckoutForm {
  customerDetails: CustomerDetailsCheckout
  recipientDetails: Omit<CustomerDetailsCheckout, 'email'>
  forSomeoneElse: boolean
  orderNotesEnabled: boolean
  orderNotes: string
  deliveryAddress: DomesticAddress
  dropoffNotes: string
  customFieldValue: string
  marketingOptIn: boolean
  saveAddress: boolean
}

interface CustomerDetailsCheckout extends CustomerDetailsParams {
  contactNumPrefix: string
}

export const ADDRESS_DEFAULTS: DomesticAddress = {
  lineOne: '',
  lineTwo: '',
  city: '',
  zip: ''
}

export const FORM_DEFAULTS: CheckoutForm = {
  customerDetails: {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  recipientDetails: {
    firstName: '',
    lastName: '',
    contactNumber: '',
    contactNumPrefix: ''
  },
  saveAddress: true,
  forSomeoneElse: false,
  orderNotesEnabled: false,
  orderNotes: '',
  deliveryAddress: ADDRESS_DEFAULTS,
  dropoffNotes: '',
  customFieldValue: '',
  marketingOptIn: false
}
