import { CurrentLocationState, OrderFulfillmentType } from 'shop/components'
import { BaseAddress, ConsumerCartPayload } from 'shop/types/cart'
import {
  DELIVERY_FULFILLMENT,
  DeliveryAddress,
  FulfillmentType,
  ORDER_AT_TABLE_FULFILLMENT,
  PICKUP_FULFILLMENT,
  ASAP_ORDER
} from 'shop/types'
import { parseTimezoneISO } from './date'
import { format } from 'date-fns'
import { buildAddressFromComponents } from 'shop/components/Landing/addressUtils'
import { timeString } from 'shop/components/Landing/utils'

/** Create cart params for initCart on shop page */
export const createDefaultSlerpCartParams = (
  storeId: string,
  defaultFulfillmentType: FulfillmentType,
  defaultFulfillmentInfo: OrderFulfillmentType
): ConsumerCartPayload => {
  const fulfillmentWindow = defaultFulfillmentInfo.earliestTimeslot
  const fulfillmentTime =
    defaultFulfillmentInfo.orderType === ASAP_ORDER
      ? null
      : format(parseTimezoneISO(fulfillmentWindow.from), 'HH:mm')

  return {
    fulfillmentDate: format(
      parseTimezoneISO(fulfillmentWindow.from),
      'yyyy-MM-dd'
    ),
    fulfillmentTime: fulfillmentTime,
    fulfillmentType: defaultFulfillmentType.toUpperCase() as FulfillmentType,
    storeId: storeId
  }
}

/** Creates delivery address for consumer api/hasura api create cart mutations */
export const constructDeliveryAddress = (
  currentLocation: CurrentLocationState | undefined,
  fulfillmentType: string,
  options: { consumerApi: boolean } = { consumerApi: false }
): BaseAddress | DeliveryAddress | null => {
  if (!!currentLocation && fulfillmentType === 'delivery') {
    if (options.consumerApi) {
      const { line_1, line_2, city, country, zip } = buildAddressFromComponents(
        currentLocation.addressComponents
      )
      return {
        lineOne: line_1,
        lineTwo: line_2,
        city,
        country,
        zip
      } as BaseAddress
    } else {
      const { flatNumber, line_1, line_2, city, country, zip } =
        buildAddressFromComponents(currentLocation.addressComponents)
      return {
        flatNumber,
        line_1,
        line_2,
        city,
        country,
        zip
      } as DeliveryAddress
    }
  } else return null
}

export const fixFulfillmentTime = (
  fulfillmentTime: string | null,
  options: { consumerApi: boolean } = { consumerApi: false }
) => {
  if (fulfillmentTime === 'immediately' || !fulfillmentTime) {
    return options.consumerApi ? null : 'asap'
  }
  return timeString(fulfillmentTime)
}

export const getFulfillmentFlags = (
  fulfillmentType: FulfillmentType | undefined
): { isOrderAtTable: boolean; isDelivery: boolean; isPickup: boolean } => {
  if (!fulfillmentType)
    return { isOrderAtTable: false, isDelivery: false, isPickup: false }
  return {
    isOrderAtTable: fulfillmentType === ORDER_AT_TABLE_FULFILLMENT,
    isDelivery: fulfillmentType === DELIVERY_FULFILLMENT,
    isPickup: fulfillmentType === PICKUP_FULFILLMENT
  }
}
