import GoogleMap from 'google-map-react'
import env from 'shop/env'
import { useAppContent, useShop } from 'shop/hooks'
import styled from '@emotion/styled'
// import { drawDeliveryRadius } from 'shop/utils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { LatLng } from 'shop/types'

export const LocationMap = () => {
  const { logoImage } = useAppContent()
  const { currentPartnerStore } = useShop()
  const { address: storeAddress, name: storeName } = currentPartnerStore || {}
  const { coordinates } = storeAddress || {}
  const { lat = 0, lng = 0 } = coordinates || {}

  const defaultCenter = {
    lat: coordinates?.lat || env.DEFAULT_MAP_CENTER.lat,
    lng: coordinates?.lng || env.DEFAULT_MAP_CENTER.lng
  }

  const createMapOptions = () => ({
    fullscreenControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER
    }
  })

  // delivery radius API is still under discussion
  // const handleGoogleApiLoaded = ({ map, maps }: any) => {
  //   if (coordinates.length) {
  //     drawDeliveryRadius({ map, maps }, { lat: coordinates[0], lng: coordinates[1], radius: 2000 })
  //   }
  // }

  return (
    <Root data-testid='location-map-root'>
      <GoogleMap
        bootstrapURLKeys={{
          key: env.MAPS_API_KEY
        }}
        defaultCenter={defaultCenter}
        center={defaultCenter}
        defaultZoom={15}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        // onGoogleApiLoaded={handleGoogleApiLoaded}
      >
        {coordinates && (
          <ShopIconWrapper lat={lat} lng={lng}>
            <ShopIcon
              src={logoImage}
              style={{
                maxWidth: '100%',
                position: 'relative',
                zIndex: 1
              }}
              alt={storeName || 'store'}
            />
          </ShopIconWrapper>
        )}
      </GoogleMap>
    </Root>
  )
}

const Root = styled.div(() => ({
  width: '100%',
  height: 'inherit'
}))

const ShopIconWrapper = styled.div<StyledHTMLElement & LatLng, Required<Theme>>(
  () => ({
    position: 'relative',
    zIndex: 10,

    width: '24px',
    height: '24px',
    padding: '4px',
    background: '#00000090',
    borderRadius: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    transform: 'translate(-50%, -50%)'
  })
)

const ShopIcon = styled.img(() => ({
  width: '100%'
}))
