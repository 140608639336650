import gql from 'graphql-tag'

const QUERY_GET_PRODUCT = gql`
  query getProduct(
    $fulfillmentType: FulfillmentType
    $fulfillmentDatetime: utc_timestamp
    $productId: uuid
    $productSlug: String
    $storeSlug: String
  ) {
    getProduct(
      fulfillmentType: $fulfillmentType
      fulfillmentDatetime: $fulfillmentDatetime
      productId: $productId
      productSlug: $productSlug
      storeSlug: $storeSlug
    ) {
      additionalImages {
        original
        standard
        thumb
      }
      allergens
      minPrice {
        basePrice
      }
      startPrice {
        basePrice
      }
      calorieData {
        caloriesPerServing
      }
      defaultVariantId
      description
      seoDescription
      id
      image {
        original
        standard
        thumb
      }
      inStock
      limit
      modifierGroups {
        id
        minimum
        modifiers {
          id
          inStock
          name
          allergens
          price {
            basePrice
          }
          restrictions {
            alcoholic
          }
          image {
            original
            standard
            thumb
          }
        }
        maximum
        name
      }
      name
      options {
        name
        values
      }
      variants {
        id
        inStock
        name
        options_allergens
        extraPrice {
          basePrice
        }
        options {
          name
          values
        }
        price {
          basePrice
        }
        restrictions {
          alcoholic
        }
        limit
      }
    }
  }
`

export { QUERY_GET_PRODUCT }
