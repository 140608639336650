import React, { useCallback, useEffect, useState } from 'react'
import { useShop } from 'shop/hooks'
import { LoaderProps } from './types'

interface Props extends LoaderProps {
  storeSlug: string
}

/** Sets currentPartnerStore (fulfillment & status store info) into state when slug is available */
export const PartnerStoreLoader = ({
  isParentLoaded = true,
  children,
  storeSlug
}: Props) => {
  const { currentPartnerStore, setCurrentPartnerStore, partner } = useShop()

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    const { stores } = partner || {}

    if (stores && storeSlug) {
      const partnerStore = stores.find((store) => store.slug === storeSlug)
      if (partnerStore) {
        setCurrentPartnerStore(partnerStore)
      }
    }

    setIsLoaded(Boolean(isParentLoaded && currentPartnerStore))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSlug, isParentLoaded, partner, setCurrentPartnerStore])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
