import gql from 'graphql-tag'

const CategoryFragment = `
    id
    name
    description
`

const ProductFragment = `
    id
    defaultVariantId
    description
    images {
      standard
    }
    inStock
    limit
    name
    quickAddAllowed
    slug
    pricing {
      maximum { 
        base 
      }
      minimum {
        base
      }  
    }
    restrictions {
      alcoholic
    }
    variants {
      allergens
      id
      inStock
      name
      limit
      pricing {
        absolute {
          base
        }
        relative {
          base
        }
      }
      restrictions {
        alcoholic
      }
      options {
        name
        value
      }
    }
`

export const QUERY_GET_STORE_CATEGORIES = gql`
  query Store(
    $id: UUID
    $slug: String
    $fulfillmentDatetime: UTCTimestamp
    $fulfillmentType: FulfillmentType
  ) {
    store(id: $id, slug: $slug) {
      categories(
        fulfillmentDatetime: $fulfillmentDatetime
        fulfillmentType: $fulfillmentType
      ) {
        ${CategoryFragment}
        products {
          ${ProductFragment}
        }
      }
    }
  }
`
