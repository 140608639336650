import styled from '@emotion/styled'
import { IoFilterOutline as SearchIcon } from 'react-icons/io5'
import { ReactComponent as BrowseIcon } from 'shop/assets/icons/menu.svg'
import { Link, LinkProps } from 'react-router-dom'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { LandingView } from '../types'
import { trackGA4CustomToggleLandingView } from 'tracker/GA/custom'

type Props = {
  currentView: LandingView
}

const LandingViewToggle = ({ currentView }: Props) => {
  const trackLandingViewToggle = (viewToSwitchTo: 'browse' | 'search') => {
    trackGA4CustomToggleLandingView('slerpGA4Tracker', viewToSwitchTo)
    trackGA4CustomToggleLandingView('merchantGA4Tracker', viewToSwitchTo)
  }
  return (
    <ToggleContainer togglePos={currentView === 'browse' ? 'left' : 'right'}>
      <ToggleSelectorLink
        data-testid='toggle-view-link-browse'
        to={'/browse'}
        isSelected={currentView === 'browse'}
        togglePos={'left'}
        onClick={() =>
          currentView !== 'browse' && trackLandingViewToggle('browse')
        }
      >
        <BrowseIcon />
        Browse
      </ToggleSelectorLink>
      <ToggleSelectorLink
        data-testid='toggle-view-link-search'
        to={'/'}
        isSelected={currentView === 'fulfillment'}
        togglePos={'right'}
        onClick={() =>
          currentView !== 'fulfillment' && trackLandingViewToggle('search')
        }
      >
        <SearchIcon />
        Search
      </ToggleSelectorLink>
    </ToggleContainer>
  )
}

const ToggleContainer = styled.div<
  StyledHTMLElement & { togglePos: 'left' | 'right' },
  Required<Theme>
>(({ theme, togglePos }) => ({
  display: 'flex',
  position: 'relative',
  margin: '0',

  [theme.mediaQueries.viewport7]: {
    margin: '0 16px'
  },

  // pseudo border bottom transition between selected options
  '&:after': {
    content: '""',
    width: '50%',
    height: '4px',
    backgroundColor: theme.colors['primary'],
    display: 'inline-block',
    position: 'absolute',
    bottom: '-4px',
    zIndex: 2,
    left: togglePos === 'left' ? 0 : '50%',
    borderTopLeftRadius: togglePos === 'left' ? '2px' : 0,
    borderBottomLeftRadius: togglePos === 'left' ? '2px' : 0,
    borderTopRightRadius: togglePos === 'right' ? '2px' : 0,
    borderBottomRightRadius: togglePos === 'right' ? '2px' : 0,
    transition: 'left .25s ease-out'
  },
  ':hover': {
    '&:after': {
      opacity: 0.8
    }
  },
  ':active': {
    '&:after': {
      opacity: 0.6
    }
  }
}))

// React is strict about unknown attributes being added to DOM elements.
// "shouldForwardProp" will allow us to ignore the warning around the prop "isSelected"
const ToggleSelectorLink = styled(Link, {
  shouldForwardProp: (prop) => {
    if (prop === 'isSelected') return false
    if (prop === 'togglePos') return false
    return true
  }
})<
  LinkProps & { isSelected: boolean; togglePos: 'left' | 'right' },
  Required<Theme>
>(({ theme, isSelected, togglePos }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  padding: '8px 0',
  cursor: 'pointer',
  lineHeight: '20px',
  color: isSelected ? theme.colors['primary'] : '#2A2A2A',
  width: '50%',
  fontSize: '16px',
  fontFamily: theme.fonts['heading'].family,
  fontWeight: 600,
  textDecoration: 'none',
  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',
  transition: 'all ease 1s',

  '& svg': {
    height: '20px',
    width: '20px',
    '& path': {
      fill: isSelected ? theme.colors['primary'] : '#2A2A2A'
    }
  },

  ':hover': {
    opacity: 0.8
  },
  ':active': {
    opacity: 0.6
  },

  // pseudo border bottom to get rounded corners
  '&:after': {
    content: '""',
    width: '100%',
    height: '4px',
    backgroundColor: '#D9D9D9',
    display: 'inline-block',
    position: 'absolute',
    bottom: '-4px',
    zIndex: 1,
    left: 0,
    borderTopLeftRadius: togglePos === 'left' ? '2px' : 0,
    borderBottomLeftRadius: togglePos === 'left' ? '2px' : 0,
    borderTopRightRadius: togglePos === 'right' ? '2px' : 0,
    borderBottomRightRadius: togglePos === 'right' ? '2px' : 0
  }
}))

export default LandingViewToggle
