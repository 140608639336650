import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Cart, Account } from '../shop/pages'
import { useConsumerCart, useShop } from 'shop/hooks'

import { StoreV1 } from './StoreV1'
import { LoadDineIn } from './LoadDineIn'
import {
  CheckoutWrapper,
  ConfirmationWrapper,
  TrackWrapper,
  PayWrapper,
  LandingWrapper,
  PasswordWrapper,
  PurchaseWrapper
} from './wrappers'
import { useRwg } from 'shop/hooks/useRwg'
import { useEffect } from 'react'
import AllTrackers from 'tracker/AllTrackers'
import { expandEnrichedEventData } from 'tracker/utils'

export const Router = () => {
  const { allStores, setCurrentStore, currentStore, config } = useShop()
  const { cart } = useConsumerCart()
  const { manageRwgToken } = useRwg()

  useEffect(() => {
    manageRwgToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.domain])

  /** Set the currentStore from the cart */
  useEffect(() => {
    if (cart?.store.slug && !!allStores.length) {
      if (cart.store.slug !== currentStore?.slug) {
        const matchedStore = allStores.find(
          (store) => store.slug === cart.store.slug
        )
        if (matchedStore) {
          setCurrentStore(matchedStore)
        }
      }
    }
    // not "currentStore?.slug" as a dependancy as we are setting it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, cart?.store.slug])

  /** Update Trackers with new Store data */
  useEffect(() => {
    if (!!currentStore?.id) {
      expandEnrichedEventData(AllTrackers.getInstance().slerpGA4Tracker, {
        store_id: currentStore.id,
        store_slug: currentStore.slug
      })
      expandEnrichedEventData(AllTrackers.getInstance().merchantGA4Tracker, {
        store_id: currentStore.id,
        store_slug: currentStore.slug
      })
    }
  }, [currentStore?.id, currentStore?.slug])

  /** Update trackers with fulfillment type & order type */
  useEffect(() => {
    if (cart) {
      const orderType = cart.fulfillment.orderType

      const additionalEventData = {
        fulfillment_type: cart.fulfillment.type,
        order_type: orderType
      }

      expandEnrichedEventData(
        AllTrackers.getInstance().slerpGA4Tracker,
        additionalEventData
      )
      expandEnrichedEventData(
        AllTrackers.getInstance().merchantGA4Tracker,
        additionalEventData
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.fulfillment.type, cart?.fulfillment.orderType])

  return (
    <BrowserRouter basename={config.path}>
      <Switch>
        <Route
          exact={true}
          path={['/', '/browse']}
          component={LandingWrapper}
          key='home'
        />
        <Route
          exact={true}
          path='/table/:slug'
          component={LoadDineIn}
          key='home'
        />
        <Route
          exact={true}
          path={['/forgot-password', '/reset-password/:token']}
          component={PasswordWrapper}
        />
      </Switch>
      <Route path='/store/:slug' component={StoreV1} key='store' />
      <Route path='/cart' component={Cart} exact />
      <Route path='/account' component={Account} />
      <Route path='/checkout' component={CheckoutWrapper} />
      <Route path='/pay' component={PayWrapper} />
      <Route path='/purchase/:id' component={PurchaseWrapper} />
      <Route path='/track/:id' component={TrackWrapper} />
      <Route
        path='/confirmation/:cart_id/:payment_id?'
        component={ConfirmationWrapper}
      />
    </BrowserRouter>
  )
}
