import styled from '@emotion/styled'
import { CartItemV2 } from '../CartItemV2'
import { OrderItem } from 'shop/types/cart'

interface Props {
  items: OrderItem[]
}

const CartItemsV2 = ({ items }: Props) => {
  return (
    <Content data-testid='cart-items'>
      {items.map(
        (
          { name, variantId, quantity, total, modifierGroups, options },
          index
        ) => (
          <div key={`variantId-${index}`} data-testid='cart-item'>
            <CartItemV2
              name={name}
              quantity={quantity}
              itemTotal={total}
              modifierGroups={modifierGroups}
              options={options}
            />
          </div>
        )
      )}
    </Content>
  )
}

const Content = styled.div(() => ({
  marginBottom: '24px'
}))

export default CartItemsV2
