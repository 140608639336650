import React from 'react'
import { LineBreak } from 'shop/components/Shop/commonStyles'
import { CART_MODAL_ORIGIN, Origin } from 'shop/types'
import { CartErrorMessage, ExtendedOrderItem, OrderItem } from 'shop/types/cart'
import ProductInfoItem from '../ProductInfoItem'

export const renderProductItems = (
  items: (ExtendedOrderItem | OrderItem)[],
  showPrice: boolean
) => {
  return items.map((item, index) => {
    const isLast = items.length === index + 1
    return (
      <React.Fragment key={`${item.id}-${index}`}>
        <ProductInfoItem item={item} showPrice={showPrice} />
        {!isLast && <LineBreak />}
      </React.Fragment>
    )
  })
}

const productErrorCopyMapGeneric = (
  hasMultipleItems: boolean,
  storeName: string
) => ({
  [CartErrorMessage.PRODUCT_PRICES_CHANGED]: {
    header: `${hasMultipleItems ? 'Some prices have changed' : 'Price change'}`,
    body: `The following product${hasMultipleItems ? 's have' : ' has'} changed. To accept these changes, click continue.`
  },
  [CartErrorMessage.PRODUCTS_OUT_OF_STOCK]: {
    header: `${hasMultipleItems ? 'Some products are out of stock' : 'Product out of stock'}`,
    body: `Unfortunately, the following product${hasMultipleItems ? 's are' : ' is'} out of stock in the ${storeName} store. Continuing will remove ${hasMultipleItems ? 'them' : 'it'} from your basket.`
  },
  [CartErrorMessage.INVALID_MODIFIERS]: {
    header: `Unavailable Modifiers`,
    body: `Unfortunately, the following product${hasMultipleItems ? 's have' : ' has'} unavailable modifier${hasMultipleItems ? 's' : '(s)'}. Continuing will remove ${hasMultipleItems ? 'them' : 'the product'} from your basket.`
  },
  [CartErrorMessage.PRODUCTS_UNAVAILABLE]: {
    header: `${hasMultipleItems ? 'Unavailable products' : `Unavailable product`}`,
    body: `Unfortunately, the following product${hasMultipleItems ? 's are' : ' is'} no longer available in the ${storeName} store. Continuing will remove ${hasMultipleItems ? 'them' : 'it'} from your basket.`
  }
})

export const getErrorCopy = (
  errorCode: CartErrorMessage,
  origin: Origin,
  hasMultipleItems: boolean,
  storeName: string
) => {
  const errorMap = getErrorCopyMap(origin)
  return errorMap(hasMultipleItems, storeName)[errorCode]
}

const getErrorCopyMap = (origin: Origin) => {
  return origin === CART_MODAL_ORIGIN
    ? cartModalProductErrorCopyMap
    : productErrorCopyMapGeneric
}

const cartModalProductErrorCopyMap = (
  hasMultipleItems: boolean,
  storeName: string
) => ({
  [CartErrorMessage.PRODUCTS_OUT_OF_STOCK]: cartModalErrorDefaultCopy(
    hasMultipleItems,
    storeName
  ),
  [CartErrorMessage.INVALID_MODIFIERS]: cartModalErrorDefaultCopy(
    hasMultipleItems,
    storeName
  ),
  [CartErrorMessage.PRODUCTS_UNAVAILABLE]: cartModalErrorDefaultCopy(
    hasMultipleItems,
    storeName
  )
})

const cartModalErrorDefaultCopy = (
  hasMultipleItems: boolean,
  storeName: string
) => ({
  header: hasMultipleItems
    ? 'Unavailable products or modifiers'
    : 'Unavailable product / modifier',
  body: `Unfortunately, the following product${hasMultipleItems ? '(s) are' : ' is'} unavailable in the ${storeName} store. Please click below to remove ${hasMultipleItems ? 'them' : 'it'} from your basket and continue.`
})
