import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  MutableRefObject,
  useRef,
  useMemo
} from 'react'
import { useModal, useReactRouter, useShop } from 'shop/hooks'
import styled from '@emotion/styled'
import { FulfillmentProps } from './types'
import { DeliveryFormNew, DeliverySignIn } from './Delivery'
import { LandingContextNew } from './LandingContext'
import {
  CartProductErrorCode,
  CurrentLocationState,
  getInvalidItemIds
} from 'shop/components'
import {
  dateString,
  timeString,
  isFulfillmentTypeEnabled,
  fadeInOutMs,
  trackFulfillmentTypeChange
} from 'shop/components/Landing/utils'
import { validateAddressDataForSaving } from './addressUtils'
import { getStoreUrl } from 'shop/utils/store'
import SaveAddressModal from './SaveAddressModal'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import SelectablePillNew from './SelectablePillNew'
import { addDays } from 'date-fns'
import {
  BaseAddress,
  CartErrorMessage,
  CartMutationResponse,
  ConsumerCartFulfillmentUpdatePayload,
  ConsumerCartPayload,
  OrderItem
} from 'shop/types/cart'
import { FulfillmentType, SHOP_ORIGIN } from 'shop/types'
import { Button } from '../Controls'
import { useConsumerCart } from 'shop/hooks/useConsumerCart'
import { constructDeliveryAddress } from 'shop/utils'
import {
  getCurrentCartId,
  getCurrentProductErrors,
  getInvalidItems
} from '../Cart/utils'
import CartProductErrorModal from '../Modal/CartProductErrorModal/CartProductErrorModal'

const FulfillmentOptionsNew = (props: FulfillmentProps) => {
  const landingContext = useContext(LandingContextNew)
  const { history } = useReactRouter()
  const { merchant, stores } = props
  const {
    initConsumerCart,
    updateFulfillmentConsumerCart,
    removeOrderItemsConsumerCart,
    cart: consumerCart
  } = useConsumerCart()
  const { config } = useShop()
  const customerId = localStorage.getItem('customerId')
  const isLoggedIn = customerId !== null
  const { pickup_address_enabled } = merchant
  const [showSaveAddressModal, setShowSaveAddressModal] =
    useState<boolean>(false)

  const cartId = getCurrentCartId(config.domain)

  const [isSwitchingFulfillment, setIsSwitchingFulfillment] =
    useState<boolean>(false)

  const [cartProductModalErrorType, setCartProductModalErrorType] =
    useState<CartProductErrorCode | null>(null)
  const [invalidItems, setInvalidItems] = useState<OrderItem[]>([])

  const {
    fulfillmentDate,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentTime,
    currentLocation,
    currentStore,
    inModal,
    addToCart,
    useCustomerAddress,
    isExtendedHour,
    setShowFulfillmentOptions,
    isEditMode,
    validStores,
    isOverlayOpen,
    setIsOverlayOpen,
    isLoginOpen,
    setIsLoginOpen,
    setCurrentLocation,
    setAddress,
    loadingDates,
    loadingStores
  } = landingContext

  const deliveryEnabled = isFulfillmentTypeEnabled(stores, 'delivery')
  const datesOrStoresLoading = useMemo(
    () => loadingDates[fulfillmentType] || loadingStores[fulfillmentType],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingDates[fulfillmentType], loadingStores[fulfillmentType]]
  )

  const { closeModal } = useModal()

  useEffect(() => {
    const defaultFulfillment = isEditMode
      ? fulfillmentType
      : deliveryEnabled
        ? 'delivery'
        : 'pickup'
    setFulfillmentType(defaultFulfillment)
    SwitchType(defaultFulfillment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryEnabled, setFulfillmentType])

  const FulfillmentSetting = {
    Form: <></>,
    onReady: () => {},
    type: ''
  }

  const selectFulfillment = (state: any, type: string) => {
    return {
      type: type,
      Form: <DeliveryFormNew />
    }
  }

  const [Fulfillment, SwitchType] = useReducer(
    selectFulfillment,
    FulfillmentSetting
  )

  const createCartParams = (): ConsumerCartPayload | null => {
    if (!currentStore) return null
    return {
      storeId: currentStore && currentStore.id,
      fulfillmentDate: fulfillmentDate && fixFulfillmentDate(),
      fulfillmentTime: fixFulfillmentTime(),
      fulfillmentType: fulfillmentType.toUpperCase() as FulfillmentType,
      deliveryAddress: constructDeliveryAddress(
        currentLocation,
        fulfillmentType,
        { consumerApi: true }
      ) as BaseAddress
    }
  }

  const fixFulfillmentDate = () => {
    const date = isExtendedHour ? addDays(fulfillmentDate, 1) : fulfillmentDate
    return dateString(date)
  }

  const fixFulfillmentTime = () => {
    if (fulfillmentTime === 'immediately' || !fulfillmentTime) return null
    return timeString(fulfillmentTime)
  }

  const cartParamsValid = (): boolean => {
    const params = createCartParams()
    const { fulfillmentDate, storeId, deliveryAddress } = params || {}
    const addressValid =
      fulfillmentType === 'delivery' ? !!deliveryAddress : true

    return !!(
      storeId &&
      fulfillmentDate &&
      addressValid &&
      !!validStores.length
    )
  }

  /** Handles functionality of the submit button */
  const handleUserSubmit = (): void | Promise<void> => {
    if (!cartParamsValid()) {
      return
    }

    // Offer user to save their address if they have no default saved addresses
    if (
      isLoggedIn &&
      currentLocation &&
      fulfillmentType === 'delivery' &&
      !useCustomerAddress &&
      validateAddressDataForSaving(currentLocation)
    ) {
      setShowSaveAddressModal(true)
    } else {
      // calls updateFulfillmentCart if cartId exists, otherwise createCart
      if (cartId) {
        return handleEditCart()
      } else {
        return handleInitCart()
      }
    }
  }

  /** Call updateFulfillmentCart with existing cart parameters */
  const handleEditCart = (priceChangeAgreed?: boolean) => {
    if (!currentStore) return
    const { ...extractedSlerpCartParams } = createCartParams()

    const updateSlerpCartParams: ConsumerCartFulfillmentUpdatePayload =
      cartId && {
        cartId,
        ...(priceChangeAgreed && { proceedWithNewPrices: true }),
        ...extractedSlerpCartParams
      }

    if (cartId && updateSlerpCartParams) {
      updateFulfillmentConsumerCart(updateSlerpCartParams).then(
        (updateFulfillmentCartRes: CartMutationResponse | null) => {
          const { errors } = updateFulfillmentCartRes || {}

          if (errors?.length) {
            const currentProductErrors = getCurrentProductErrors(errors)

            if (consumerCart && currentProductErrors.length) {
              const currentOrderItems = consumerCart?.orderItems
              const invalidItems = getInvalidItems(
                currentProductErrors[0],
                currentOrderItems
              )
              setInvalidItems(invalidItems)
              setIsOverlayOpen(true)
              setCartProductModalErrorType(currentProductErrors[0].message)
            }
            return
          }

          // onClose callback handles cleanup on user submission of modal form
          if (props.onClose) {
            props.onClose()
          }
          history.push(getStoreUrl(currentStore.slug))

          if (isOverlayOpen) {
            setIsOverlayOpen(false)
            setTimeout(() => setCartProductModalErrorType(null), fadeInOutMs)
          }
        }
      )
    }
  }

  /** Create new cart via consumer API & handles response */
  const handleInitCart = async () => {
    if (!currentStore) return
    const params = createCartParams()
    if (params) {
      initConsumerCart({ variables: params }).then((res) => {
        if (!res || !res.cart) {
          return
        }

        const { id: cartId } = res.cart || {}

        if (props.onClose) {
          props.onClose()
        }
        const storeSlug = currentStore?.slug
        // push to the new store location
        history.push(getStoreUrl(currentStore.slug))
        if (inModal && addToCart) {
          addToCart({ cartId, fulfillmentType, storeSlug })
        }
      })
    }
  }

  /** Handles removal of products with PRODUCTS_UNAVAILABLE / PRODUCTS_OUT_OF_STOCK / INVALID_MODIFIERS / PRODUCT_PRICES_CHANGED items on updateFulfillmentConsumerCart */
  const onContinue = () => {
    if (cartProductModalErrorType === CartErrorMessage.PRODUCT_PRICES_CHANGED) {
      handleEditCart(true)
      return
    }

    const invalidItemIds = getInvalidItemIds(invalidItems)

    removeOrderItemsConsumerCart({ ids: invalidItemIds }).then(() => {
      handleEditCart()
    })
  }

  const renderContinueButtons = (): JSX.Element => {
    return (
      <>
        <ViewMenuButton
          disabled={!cartParamsValid()}
          onClick={handleUserSubmit}
          testId={'continueButton'}
        >
          View Menu
        </ViewMenuButton>
      </>
    )
  }

  const pickupEnabled = isFulfillmentTypeEnabled(stores, 'pickup')

  // Show fulfillment options even if only one of delivery or pickup is enabled
  const showFulfillmentOptions = deliveryEnabled && pickupEnabled

  useEffect(() => {
    setShowFulfillmentOptions(!!showFulfillmentOptions)
  }, [showFulfillmentOptions, setShowFulfillmentOptions])

  const { isModalOpen, openModal, loginModal } = useModal()

  const handleOpenLoginModal = () => {
    setIsOverlayOpen(!isOverlayOpen)
    setIsLoginOpen(true)
    openModal('login')
  }

  const handleCloseLoginModal = () => {
    setIsOverlayOpen(!isOverlayOpen)
    setTimeout(() => setIsLoginOpen(false), 225)
    closeModal('login')

    // Ensure scrolllock is disabled when comnig back into the fulfillment modal from the login modal
    document.body.style.overflow = 'hidden'
  }

  const currentLocationRef: MutableRefObject<CurrentLocationState | undefined> =
    useRef(undefined)

  const handleSwitchFulfillment = (fulfillmentType: 'pickup' | 'delivery') => {
    if (datesOrStoresLoading) return
    setIsSwitchingFulfillment(true)
    // If user switches back to delivery, set currentLocation/address to ref value
    if (fulfillmentType === 'delivery') {
      if (!pickup_address_enabled && currentLocationRef.current) {
        setCurrentLocation(currentLocationRef.current)
        setAddress(currentLocationRef.current.address)
      }
      SwitchType('delivery')
      setFulfillmentType('delivery')
    } else {
      // save address in ref if user switches back to pickup
      if (!pickup_address_enabled) {
        currentLocationRef.current = currentLocation
      }
      SwitchType('pickup')
      setFulfillmentType('pickup')
    }
    const uppercaseFulfillmentType =
      fulfillmentType.toUpperCase() as FulfillmentType
    trackFulfillmentTypeChange(uppercaseFulfillmentType, 'modal')
    setTimeout(() => setIsSwitchingFulfillment(false), 200)
  }

  return (
    <>
      {isModalOpen('login') &&
        loginModal({
          merchantId: merchant.id,
          onClose: handleCloseLoginModal,
          inFulfillmentModal: true,
          isOverlay: isOverlayOpen,
          isOpen: isLoginOpen,
          inModal: true
        })}
      {currentStore && cartProductModalErrorType && (
        <CartProductErrorModal
          onClose={() => {
            setIsOverlayOpen(false)
            setTimeout(() => setCartProductModalErrorType(null), fadeInOutMs)
          }}
          invalidItems={invalidItems}
          onContinue={onContinue}
          storeName={currentStore?.name}
          origin={SHOP_ORIGIN}
          isOpen={isOverlayOpen}
          productErrorCode={cartProductModalErrorType}
        />
      )}
      {showSaveAddressModal && currentLocation && (
        <SaveAddressModal
          handleInitCart={cartId ? handleEditCart : handleInitCart}
          setShowSaveAddressModal={setShowSaveAddressModal}
          currentLocation={currentLocation}
        />
      )}

      <FormContainer>
        {showFulfillmentOptions && (
          <FulfillmentSelectContainer data-testid='fulfillment-select-container'>
            <SelectablePillNew
              options={[
                {
                  title: 'Delivery',
                  selected: Fulfillment.type === 'delivery',
                  type: 'delivery',
                  onClick: () => {
                    handleSwitchFulfillment('delivery')
                  }
                },
                {
                  title: 'Collection',
                  selected: Fulfillment.type === 'pickup',
                  type: 'pickup',
                  onClick: () => {
                    handleSwitchFulfillment('pickup')
                  }
                }
              ]}
            />
          </FulfillmentSelectContainer>
        )}
        <FormContentContainer {...{ isSwitchingFulfillment }}>
          {Fulfillment.Form}
        </FormContentContainer>
      </FormContainer>
      <ButtonsContainer>
        <ButtonContainer>{renderContinueButtons()}</ButtonContainer>
      </ButtonsContainer>
      {!isLoggedIn && (
        <DeliverySignInCTAContainer onClick={handleOpenLoginModal}>
          <DeliverySignIn />
        </DeliverySignInCTAContainer>
      )}
    </>
  )
}

const DeliverySignInCTAContainer = styled.div<StyledHTMLElement>(() => ({
  margin: '0 auto 32px'
}))

const ViewMenuButton = styled(Button)(() => ({
  marginBottom: '0'
}))

const FormContainer = styled.div<
  StyledHTMLElement & { showFulfillmentOptions?: boolean },
  Required<Theme>
>(({ theme }) => ({
  padding: `0 0 ${theme.space[5]}px`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',

  [theme.mediaQueries.viewport7]: {
    overflow: 'visible',
    width: '100%',
    transform: 'none',
    paddingTop: 0,
    ':before': {
      display: 'none'
    }
  }
}))

const FulfillmentSelectContainer = styled.div<
  StyledHTMLElement,
  Required<Theme>
>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  paddingBottom: '8px',
  [theme.mediaQueries.viewport7]: {
    gap: '8px',
    justifyContent: 'left'
  }
}))

const ButtonsContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    marginBottom: `${theme.space[4]}px`,
    '&:button': {
      color: theme.colors['primary'],
      backgroundColor: 'white',
      border: `1px solid ${theme.colors['primary']}`
    }
  })
)

const FormContentContainer = styled.div<
  StyledHTMLElement & { isSwitchingFulfillment: boolean },
  Required<Theme>
>(({ theme, isSwitchingFulfillment = false }) => ({
  opacity: isSwitchingFulfillment ? 0 : 1,
  transition: !isSwitchingFulfillment ? 'opacity ease-out 200ms' : ''
}))

const ButtonContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    position: 'sticky',
    width: `calc(100% + ${theme.space[3] * 2}px)`,
    transform: `translateX(-${theme.space[3]}px)`,
    bottom: 0,
    padding: ` 0 ${theme.space[3]}px ${theme.space[3]}px`,
    backgroundColor: 'white',
    zIndex: theme.zIndex.stickyHeader
  })
)

export default FulfillmentOptionsNew
