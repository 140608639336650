import { useHistory } from 'react-router-dom'
import { useShop } from 'shop/hooks'
import ErrorModal from 'shop/components/Modal/ErrorModal'

const cartErrorGlossary = (
  errorCode: string,
  storeName: string | null,
  history: any
): {
  title: string
  messages: string[]
  buttonMessage: string
  handleButtonClick: () => void
} => {
  let errorCase = errorCode
  if (!storeName) errorCase = 'default'

  switch (errorCase) {
    case 'invalid_fulfillment_type_delivery': {
      return {
        title: 'Delivery is currently not available',
        messages: [
          `Unfortunately, we currently don't offer delivery from ${storeName}.`,
          `Please click below to place a pick up order.`
        ],
        buttonMessage: 'Order Pickup',
        handleButtonClick: () => {
          history.replace({
            search: '?fulfillment_type=pickup'
          })
          history.go(0)
        }
      }
    }
    case 'invalid_fulfillment_type_pickup': {
      return {
        title: 'Pickup is currently not available',
        messages: [
          `Unfortunately, we currently don't offer pickup from ${storeName}.`,
          `Please click below to place a delivery order.`
        ],
        buttonMessage: 'Order Delivery',
        handleButtonClick: () => {
          history.replace({
            search: '?fulfillment_type=delivery'
          })
          history.go(0)
        }
      }
    }
    default: {
      return {
        title: 'Oops, something has gone wrong',
        messages: [
          'We have encountered an unexpected problem. Please go back and try again.'
        ],
        buttonMessage: 'Try again',
        handleButtonClick: () => history.push('/')
      }
    }
  }
}

interface CartErrorModalProps {
  errorCode: string
}

const CartErrorModal = ({ errorCode }: CartErrorModalProps) => {
  const history = useHistory()
  const { currentPartnerStore } = useShop()
  const { name: storeName = '' } = currentPartnerStore || {}

  return (
    <ErrorModal
      errorCode={errorCode}
      errorTitle={cartErrorGlossary(errorCode, storeName, history).title}
      errorMessages={cartErrorGlossary(errorCode, storeName, history).messages}
      primaryButton={{
        message: cartErrorGlossary(errorCode, storeName, history).buttonMessage,
        onClick: cartErrorGlossary(errorCode, storeName, history)
          .handleButtonClick
      }}
    />
  )
}

export default CartErrorModal
