import styled from '@emotion/styled'
import { IconTitle } from './IconTitle'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { useShop } from 'shop/hooks'
import { SimpleFormat } from 'shop/utils'

export const ShopAbout = () => {
  const { partner } = useShop()
  const welcomeMessage = partner?.content.welcome.message

  return (
    <Root>
      <IconTitle icon={<IoMdInformationCircleOutline />}>About</IconTitle>
      <Description>
        <SimpleFormat>{welcomeMessage ?? ''}</SimpleFormat>
      </Description>
    </Root>
  )
}

const Root = styled.div(() => ({}))

const Description = styled.div(({ theme }: any) => ({
  margin: '8px 0 0 24px',
  display: 'flex',
  flexFlow: 'column',
  marginTop: '16px',
  lineHeight: 'normal',
  fontSize: '8px',

  [theme.mediaQueries.viewport7]: {
    fontSize: '10px'
  },

  'h1, h1 *': {
    fontSize: theme.fontSizes[3],
    fontWeight: 600,
    margin: 0,
    lineHeight: 'normal'
  },

  'p, h6, p *, h6 *': {
    margin: '0',
    fontSize: theme.fontSizes[1],
    fontWeight: 400,
    lineHeight: 'normal'
  },

  'strong, b, a': {
    color: theme.colors.black,
    fontWeight: 'bolder',
    lineHeight: 'normal'
  },

  a: {
    textDecoration: 'underline'
  }
}))
