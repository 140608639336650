import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { IoLocationOutline as LocationIcon } from 'react-icons/io5'
import { createAddressString } from 'shop/utils/address'
import { CustomerAddress } from 'shop/types'

type Props = {
  customerAddress: CustomerAddress
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
}

const SavedAddressItem = ({
  customerAddress,
  selectCustomerAddress
}: Props) => {
  const hasLabel = !!customerAddress.name
  const formattedAddress = createAddressString(customerAddress.address, {
    withCommas: true
  })
  return (
    <ItemContainer
      hasLabel={hasLabel}
      onClick={() => selectCustomerAddress(customerAddress)}
    >
      <LocationIcon />
      <AddressContentContainer>
        {hasLabel && (
          <AddressNameLabel>{customerAddress.name}</AddressNameLabel>
        )}
        <AddressContent>{formattedAddress}</AddressContent>
      </AddressContentContainer>
      {customerAddress.isDefaultShippingAddress && (
        <DefaultLabel>Default</DefaultLabel>
      )}
    </ItemContainer>
  )
}

const ItemContainer = styled.div<
  StyledHTMLElement & { hasLabel: boolean },
  Required<Theme>
>(({ theme, hasLabel }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: hasLabel ? '4.5px 0' : '14px 0',
  gap: '8px',
  fontFamily: theme.fonts['body'].family,
  borderRadius: '12px',
  cursor: 'pointer',

  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',

  '& svg': {
    height: '20px',
    width: '20px'
  },

  '&:hover': {
    background: '#FAFAFA'
  }
}))

const AddressContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '4px',
    minWidth: 0 // for ellipsis to work with flex
  })
)
const AddressNameLabel = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    color: '#2A2A2A',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)
const AddressContent = styled.p<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '14px',
    lineHeight: '16px',
    color: '#59595A',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)

const DefaultLabel = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    fontSize: '12px',
    fontWeight: 500,
    color: '#2A2A2A',
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#F5F5F5'
  })
)

export default SavedAddressItem
