import {
  FulfillmentType,
  FulfillmentWindow,
  Image,
  OrderType
} from 'shop/types'

export interface LandingProps {
  merchant: Merchant
  stores: Store[]
}

export interface FulfillmentProps extends LandingProps {
  onReady?: () => {}
  onClose?: () => void
}

export type Merchant = {
  id: string
  name: string
  slug: string
  vehicles: any
  setting: Settings
  closed_store_message: string
  order_note_placeholder: string
  tag_manager_id: string
  pickup_address_enabled?: boolean
  order_custom_field: boolean
  order_custom_field_name: string
  order_custom_field_placeholder: string
  order_custom_field_mandatory: boolean
  order_custom_field_apply_for: string[]
  for_testing?: boolean
} & Settings

export type StoreOrderType = 'ALL' | 'SAMEDAY' | 'PREORDER'

export type MessageType = 'WARNING' | 'ANNOUNCEMENT'

export interface Message {
  title: string
  description: string
  messageType: MessageType
  orderType: StoreOrderType
}

export type Partner = {
  content: {
    welcome: {
      header: string
      message: string
    }
    images: {
      banner: Image
      logos: {
        alternative?: Image
        primary?: Image
      }
      splash?: Image
    }
    messages: Message[]
    promotionalBanner: string
  }
  name: string
  seo: {
    description: string | null
    enabled: boolean
    tagline: string | null
  }
  stores: PartnerStore[]
}

export type PartnerStore = {
  id: string
  address: PartnerAddress
  contactNumber: string
  description: string | null
  name: string
  openingHours: OpeningHour[]
  orderFulfillmentTypes: OrderFulfillmentType[]
  seo: { description: string | null }
  status: StoreStatus
  slug: string
  timezone: string
}

export enum StoreStatus {
  ACCEPTING_PREORDER = 'ACCEPTING_PREORDER',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export type PartnerAddress = {
  city: string
  coordinates: LatLng
  lineOne: string | null
  lineTwo: string | null
  zip: string | null
}

export type OpeningHour = {
  dayOfWeek: DayOfWeek
  shifts: {
    startTime: string // Hour minute scalar type: time in HH:mm format as a string in local timezone. E.g. "09:00"
    endTime: string // Hour minute scalar type: time in HH:mm format as a string in local timezone. E.g. "09:00"
  }[]
}

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY'

export type OrderFulfillmentType = {
  fulfillmentType: FulfillmentType
  orderType: OrderType
  earliestTimeslot: {
    from: string // e.g. "2024-11-07T17:06:08.759348Z"
    to: string // e.g. "2024-11-07T17:06:08.759348Z"
  }
  minimumOrderValue: string | null
  status: 'ACCEPTING_ORDERS' | 'NOT_ACCEPTING_ORDERS'
}

export type DeliveryAreaSetting = {
  delivery_area?: any[]
  busy_delivery_area?: any[]
  busy_delivery_area_enabled?: any[]
}

export type Settings = {
  email: string
  address: string

  tag_manager_id: string
  pixel_id: string

  gift_wrap_text: string
  gift_wrap_price: number | string
  gift_wrap_enabled: true
  gift_wrap_placeholder: string

  closed_store_message: string
  order_note_placeholder: string
  delivery_note_placeholder: string
  pickup_address_enabled?: boolean
}

export type Cutoffs = {
  id: string
  sunday: Cutoff
  monday: Cutoff
  tuesday: Cutoff
  wednesday: Cutoff
  thursday: Cutoff
  friday: Cutoff
  saturday: Cutoff
}

export type Cutoff = {
  cutoff_date: string
  day_diff: number | null
  enabled: boolean
  id: string
  time: string | null
}

// Deprecated soon.
export type Store = {
  id: string
  name: string
  slug: string
  is_open: boolean
  cutoffs?: Cutoffs[]
  special_schedules?: SpecialSchedule[]
  address: {
    line_1: string
    line_2: string
    city: string
    zip: string
    geom: {
      coordinates: [number, number]
    }
  }
  settings: StoreSettings
  distanceFromCurrentLocation?: number
  sameday_delivery_minimum_order_value?: number
  preorder_delivery_minimum_order_value?: number
  sameday_pickup_minimum_order_value?: number
  preorder_pickup_minimum_order_value?: number
  pre_order_settings: Array<StorePreOrderSettings> | null
  split_hours: SplitHour[]
}

export type SplitHour = {
  day_of_week: string
  enabled: boolean
  closed: boolean
  id: string
  seconds_length: number
  special_date: string | null
  start_time: string
}

export type StorePreOrderSettings = {
  delivery_enabled: boolean
  delivery_times: 'opening_hours' | 'custom_hours'
  delivery_interval: number
  delivery_radius: number

  pickup_enabled: boolean
  pickup_interval: number
  pickup_times: 'opening_hours' | 'custom_hours'
  pickup_schedule: OperatingSchedules | null

  pre_order_day_allowance: number
  cutoffs?: Cutoffs | null
  special_cutoffs_map: { [key: string]: Cutoff } | null

  delivery_area_setting?: DeliveryAreaSetting
}

export type StoreSettings = {
  delivery_radius: number
  scheduled_delivery?: boolean
  scheduled_pickup?: boolean
  same_day_pickup?: boolean
  same_day_delivery?: boolean

  table_enabled?: boolean
  table_welcome_message?: string
  table_count?: number
  table_allow_counter_pickup?: boolean
  table_counter_pickup_message?: string
  table_choices?: string

  asap_only?: boolean
  asap_enabled?: boolean

  delivery_interval: number
  pickup_interval: number

  busy_preparation_time: number
  moderate_preparation_time: number
  quiet_preparation_time: number

  id: string

  prep_mode: 'quiet' | 'moderate' | 'busy'
} & DeliveryAreaSetting

export type SpecialCutoffs = {
  [key: string]: SpecialCutoff
}

export type SpecialCutoff = Cutoff

export type OperatingSchedule = {
  id: string
  end?: string | null
  start?: string | null
  enabled: boolean
}

export type SpecialSchedule = OperatingSchedule & {
  special_date: string
}

export type OperatingSchedules = {
  id: string
  sunday: OperatingSchedule
  monday: OperatingSchedule
  tuesday: OperatingSchedule
  wednesday: OperatingSchedule
  thursday: OperatingSchedule
  friday: OperatingSchedule
  saturday: OperatingSchedule
}

export interface LatLng {
  lat: number
  lng: number
}

export type AdditionalStoreFee = {
  is_active: boolean
  name?: string
  amount?: number
  amount_type?: 'percentage' | 'fixed'
  fulfillment_types?: {
    delivery: boolean
    pickup: boolean
    table_ordering: boolean
  }
}

export interface SelectedModifier {
  id: string
  modifier_id: string
  quantity: number
  amount: number
  price: number
  vat?: number
  modifier_group_id: string
  sku?: string
}

export interface SelectedModifiers {
  [key: string]: SelectedModifier
}

export interface AppliedModifiers {
  [key: string]: AppliedModifier
}

export interface AppliedModifier {
  id: string
  modifier_id: string
  quantity: number
  amount: number
  price: number
  modifier_group_id: string
  modifier: {
    id: string
    name: string
    restrictions: Restrictions
    sku: string
  }
  modifier_group: {
    id: string
    name: string
  }
  vat: number
}

type Restrictions = {
  alcoholic: boolean
}

export type TimeSlot = {
  value: string
  range: string
  extended: boolean
}

export type AddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export type CurrentLocationState = {
  address: string
  // saved addresses have an addressId
  addressId?: string
  latLng: LatLng
  addressComponents: AddressComponent[] | []
  postcode?: string
}

export type Fulfillment = {
  fulfillmentWindow: FulfillmentWindow
  minimumOrderValue: string
  orderType: OrderType
  fulfillmentType?: FulfillmentType
  orderableAt: string
}

// Type used for getValidStores results
export type ValidStore = {
  id: string
  name: string
  address: {
    line_1: string
    line_2: string
    city: string
    zip: string
    geom: {
      coordinates: [number, number]
    }
  }
  fulfillmentTimeslots: ValidStoreTimeSlot[]
  earliestDeliveryFulfillment?: Fulfillment
  earliestPickupFulfillment?: Fulfillment
  slug: string
  settings?: {
    table_enabled: boolean
    table_welcome_message: string
    table_count: number
    table_choices: string
    table_allow_counter_pickup: boolean
    table_counter_pickup_message: string
  }
}

export type ValidStoreTimeSlot = {
  startTime: string
  endTime: string
  value: string
}

export type AvailableDates = {
  pickup: { [key: string]: Date[] }
  delivery: { [key: string]: Date[] }
}

export type AddToCartParams = {
  cartId: string
  fulfillmentType?: string | null
  storeSlug?: string | null
}

export type LandingView = 'browse' | 'fulfillment'
