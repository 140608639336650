import { DELIVERY_FULFILLMENT, ORDER_AT_TABLE_FULFILLMENT } from 'shop/types'
import { Fulfillment as CartFulfillment } from 'shop/types/cart'
import { getFulfillmentFlags } from 'shop/utils'
import { OrderFulfillmentType } from '../Landing/types'

/** Takes default fulfillment info & current cart info and returns vars for use in DeliverySummary */
export const getFulfillmentDetails = (
  cartFulfillment: CartFulfillment | null,
  defaultFulfillmentInfo: OrderFulfillmentType | null
) => {
  const { isDelivery: cartIsDelivery, isOrderAtTable } = getFulfillmentFlags(
    cartFulfillment?.type
  )

  const isDelivery = cartFulfillment
    ? cartIsDelivery
    : defaultFulfillmentInfo?.fulfillmentType === DELIVERY_FULFILLMENT

  const defaultFulfillment = cartFulfillment ? null : defaultFulfillmentInfo

  const fulfillmentWindow = cartFulfillment
    ? cartFulfillment.window
    : defaultFulfillment?.earliestTimeslot

  const orderType = cartFulfillment
    ? cartFulfillment.orderType
    : defaultFulfillment?.orderType

  const isOat = cartFulfillment
    ? isOrderAtTable
    : defaultFulfillmentInfo?.fulfillmentType === ORDER_AT_TABLE_FULFILLMENT

  return { isDelivery, defaultFulfillment, fulfillmentWindow, orderType, isOat }
}
