import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

interface LogoProps {
  imageUrl: string
  url: string
}

const Brand = styled.div(({ theme, urlIsSame }: any) => ({
  display: 'flex',
  alignItems: 'center',
  height: '70%',
  pointerEvents: urlIsSame ? 'none' : 'auto',
  [theme.mediaQueries.viewport7]: {
    height: '100%'
  }
}))

const StyledLink = styled(Link)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '100%',
  width: '100%'
}))

const Image = styled.img(() => ({
  height: 'auto',
  maxHeight: '32px',
  width: 'auto',
  maxWidth: '100%'
}))

const Logo = ({ imageUrl, url }: LogoProps) => {
  const isUrlSame = `/order` + url === window.location.pathname
  return (
    <Brand urlIsSame={isUrlSame}>
      <StyledLink data-testid='nav-bar-logo-link' to={url}>
        <Image src={imageUrl} />
      </StyledLink>
    </Brand>
  )
}

export default Logo
