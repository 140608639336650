import { AsapTime } from './AsapTime'
import { ScheduledTime } from './ScheduledTime'
import { ASAP_ORDER, FulfillmentWindow, OrderType } from 'shop/types'
import { parseTimezoneISO } from 'shop/utils'

type FulfillmentTimeProps = {
  fulfillmentWindow: FulfillmentWindow
  orderType?: OrderType
  storeDefaultsOnly?: boolean
  variant?: 'shop' | 'list'
  dateTimeOverride?: Date
}

export const FulfillmentTime = ({
  fulfillmentWindow,
  orderType,
  variant,
  dateTimeOverride
}: FulfillmentTimeProps) => {
  if (!fulfillmentWindow) {
    return <></>
  }

  const { from, to } = fulfillmentWindow

  const parsedFrom = parseTimezoneISO(from)
  const parsedTo = parseTimezoneISO(to)

  const isScheduled = orderType !== ASAP_ORDER

  return isScheduled ? (
    <ScheduledTime from={parsedFrom} to={parsedTo} variant={variant} />
  ) : (
    <AsapTime
      from={parsedFrom}
      to={parsedTo}
      variant={variant}
      dateTimeOverride={dateTimeOverride}
    />
  )
}
