import { ReactElement, useState } from 'react'
import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import LocationSelectModal from './LocationSelectModal'
import { BiEditAlt as EditIcon } from 'react-icons/bi'
import { IoLocationOutline as LocationIcon } from 'react-icons/io5'
import { CustomerAddress } from 'shop/types'

type PseudoFormInputProps = {
  address: string
  onChange: (newAddress: string) => void
  onSelect: (newAddress: string) => void
  label?: string
  id?: string
  customerAddresses?: CustomerAddress[]
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
}

export const PseudoFormInput = ({
  address,
  onChange,
  onSelect,
  id,
  customerAddresses,
  selectCustomerAddress
}: PseudoFormInputProps) => {
  const [showLocationSelect, setShowLocationSelect] = useState(false)
  const handleSelect = (address: string) => {
    onSelect(address)
    setTimeout(() => setShowLocationSelect(false), 200)
  }

  const handleSelectCustomerAddress = (customerAddress: CustomerAddress) => {
    selectCustomerAddress(customerAddress)
    setTimeout(() => setShowLocationSelect(false), 200)
  }

  return (
    <>
      <StyledPseduoFormInput
        id={id}
        onContainerClick={() => setShowLocationSelect(true)}
        showEditIcon={true}
      >
        {/* Read only input for Fulfillment Form */}
        <input
          placeholder='Type your postcode here'
          value={address}
          readOnly={true}
        />
      </StyledPseduoFormInput>
      {showLocationSelect && (
        <LocationSelectModal
          address={address}
          onChange={onChange}
          onSelect={handleSelect}
          onClose={() => setShowLocationSelect(false)}
          customerAddresses={customerAddresses}
          selectCustomerAddress={handleSelectCustomerAddress}
        />
      )}
    </>
  )
}

type StyledPseduoFormInputProps = {
  onContainerClick?: () => void
  children: ReactElement
  showEditIcon?: boolean
  id?: string
}

export const StyledPseduoFormInput = ({
  onContainerClick,
  children,
  showEditIcon = false,
  id
}: StyledPseduoFormInputProps) => {
  return (
    <InputContainer
      id={id}
      data-testid={id}
      isClickable={!!onContainerClick}
      onClick={onContainerClick && onContainerClick}
    >
      <IconContainer>
        <LocationIcon />
      </IconContainer>
      <ContentContainer>{children}</ContentContainer>
      {showEditIcon && <EditIcon />}
    </InputContainer>
  )
}

const InputContainer = styled.div<
  StyledHTMLElement & { isClickable: boolean },
  Required<Theme>
>(({ theme, isClickable }) => ({
  display: 'flex',
  outline: 0,
  borderRadius: '12px',
  border: `1px solid ${theme.colors['lineColor']}`,
  padding: '9px 20px 9px 10px',
  alignItems: 'center',
  backgroundColor: 'white',
  cursor: isClickable ? 'pointer' : 'unset',
  lineHeight: '30px',

  // disable blue hightlight of user clicks
  WebkitTapHighlightColor: 'transparent',

  // disable lastpass
  '& [data-lastpass-icon-root]': {
    display: 'none'
  }
}))

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    display: 'flex',
    gap: '12px',
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: theme.fontWeights.light,
    fontFamily: theme.fonts.body.family,

    '& input': {
      width: '100%',
      outline: 0,
      border: 0,
      padding: 0,
      fontSize: '14px'
    }
  })
)

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    aspectRatio: '1/1',

    '& svg': {
      color: '#2A2A2A'
    }
  })
)
