import styled from '@emotion/styled'
import {
  FlashContext,
  useReactRouter,
  useShop,
  useModal,
  useConsumerCart
} from 'shop/hooks'
import SoldOutOverlay from './SoldOutOverlay'
import { IoAddOutline as AddIcon } from 'react-icons/io5'
import { HiCheck as CheckIcon } from 'react-icons/hi'
import { AiOutlineLoading3Quarters as LoadingIcon } from 'react-icons/ai'
import { keyframes } from '@emotion/core'

import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'

import React, { SyntheticEvent, useCallback, useContext, useState } from 'react'
import {
  merchantGA4EcommTrackAddToCart,
  slerpGA4EcommTrackAddToCart
} from 'tracker/GA/ecommerce'
import { CategoryProduct } from '../Shop/Categories'
import { AddToCartParams } from '../Landing'
import { OrderItem } from 'shop/types/cart'
import { getCurrentCartId } from '../Cart/utils'

const AddToCartButton = ({
  onClick,
  buttonState,
  href
}: {
  onClick: (e: SyntheticEvent) => void
  buttonState: ProductCardButtonState
  href?: string
}) =>
  href ? (
    <AnchorWrapper
      href={href}
      onClick={(e: SyntheticEvent) => onClick(e)}
      disabled={buttonState === 'loading' || buttonState === 'done'}
      data-testid='add-to-cart-button'
      rel='nofollow'
    >
      {getIcon(buttonState)}
    </AnchorWrapper>
  ) : (
    <ButtonWrapper
      onClick={(e: SyntheticEvent) => onClick(e)}
      disabled={buttonState === 'loading' || buttonState === 'done'}
      data-testid='add-to-cart-button'
    >
      {getIcon(buttonState)}
    </ButtonWrapper>
  )

const getIcon = (buttonState?: string | null) => {
  if (buttonState === 'loading') return <Loading size={20} />
  if (buttonState === 'done') return <CheckIcon color='green' size={20} />
  return <AddIcon size={20} />
}

const getCartItem = (orderItems: OrderItem[], id: string) =>
  orderItems.find((item) => item.variantId === id)

export interface ProductCardProps {
  product: CategoryProduct
  productCategory: string
}

type ProductCardButtonState = 'loading' | 'done' | null

const ProductCard = ({ product, productCategory }: ProductCardProps) => {
  const [showFulfillmentForm, setShowFulfillmentForm] = useState(false)
  const [isProductImageClick, setIsProductImageClick] = useState(false)
  const {
    defaultVariantId,
    images,
    inStock,
    name,
    pricing,
    quickAddAllowed,
    limit,
    slug: productSlug
  } = product
  const { maximum: maxPrice, minimum: minPrice } = pricing
  const { match, history } = useReactRouter<{ slug: string }>()
  const fallbackImage = `https://placehold.co/601x400/random/random?text=${(
    name || 'Slerp'
  ).substring(0, 1)}`
  const imageUrl = images[0]?.standard || fallbackImage

  const { isModalOpen, openModal, closeModal, fulfillmentModal } = useModal()
  const { cart, cartLoading, createOrderItemConsumerCart } = useConsumerCart()
  const { partner, config } = useShop()
  const cartId = getCurrentCartId(config.domain)
  const [buttonState, setButtonState] = useState<ProductCardButtonState>(null)
  const { pushMessage } = useContext(FlashContext)

  const cartItem = getCartItem(cart?.orderItems || [], defaultVariantId)

  /** If no cart open modal, else route to product card */
  const handleProductClick = (e: SyntheticEvent) => {
    e.preventDefault()

    if (!cartId) {
      if (!isModalOpen('fulfillment')) {
        // User has clicked product card, not quick add button
        setIsProductImageClick(true)
        setShowFulfillmentForm(true)
        openModal('fulfillment')
      }
    } else {
      routeToProductModal(cart?.store.slug)
    }
  }

  const routeToProductModal = (storeSlug?: string) => {
    if (storeSlug) {
      const link = `/store/${storeSlug}/${productSlug}`
      const state = { product: { ...product }, productCategory }
      history.push(link, state)
    }
  }

  const ecommTrackAddToCartCallback = (params: {
    storeName: string
    merchantName: string
  }) => {
    // eccommerce tracking
    const trackParams = {
      currency: 'gbp',
      value: parseFloat(minPrice.base), // quick-add will always be base price
      items: [
        {
          item_id: product.id,
          item_name: product.name,
          affiliation: params.storeName,
          item_brand: params.merchantName,
          item_variant: product.name, // quick-add will always be default name
          price: parseFloat(minPrice.base), // quick-add will always be base price
          quantity: 1 // quick-add will only add 1
        }
      ]
    }
    slerpGA4EcommTrackAddToCart(trackParams)
    merchantGA4EcommTrackAddToCart(trackParams)
  }

  const handleFormComplete = ({ cartId, storeSlug }: AddToCartParams) => {
    // If not a quick add item, close modal & route to product
    if (!quickAddAllowed || (quickAddAllowed && isProductImageClick)) {
      if (isModalOpen('fulfillment') || showFulfillmentForm) {
        closeModal('fulfillment')
        setShowFulfillmentForm(false)
      }
      routeToProductModal(storeSlug || '')
      setButtonState(null)
      return
    }
    addItemToCart()
  }

  /** Quick Add Item to Cart */
  const addItemToCart = () => {
    setButtonState('loading')

    const payload = {
      variantId: defaultVariantId,
      quantity: 1
    }

    const projectedItemQuantity = (cartItem && cartItem.quantity + 1) || 1

    if (limit !== null && projectedItemQuantity > limit) {
      setTimeout(() => setButtonState(null), 1500)
      return pushMessage({
        content: `The limit has been reached for ${product.name}`,
        timeout: 1500,
        type: 'error'
      })
    }

    createOrderItemConsumerCart(payload)
      .then(() => {
        ecommTrackAddToCartCallback({
          storeName: cart?.store.name || '',
          merchantName: partner?.name || ''
        })
        setButtonState('done')
        setTimeout(() => setButtonState(null), 400)
      })
      .catch((err) => {
        console.error('ADD TO CART ERROR: ', err)
        setButtonState(null)
      })
      .finally(() => {
        closeModal('fulfillment')
        setShowFulfillmentForm(false)
      })

    const trackingBody = {
      category: productCategory ? productCategory : '',
      action: TrackableEvent.ProductAdded,
      label: product.name,
      value: 1
    }

    trackUserActionsGA4(trackingBody, 'slerpGA4Tracking')

    // legacy tracking
    trackUserActionsFBPixel('AddToCart', {
      content_name: product.name,
      content_type: 'product',
      contents: [{ id: product.id, quantity: 1 }]
    })
    trackUserActionsGA4(trackingBody, 'merchantGA4Tracking')
  }

  const handleAddToCart = (event: SyntheticEvent) => {
    event.preventDefault()
    // Prevents quick add if cart is loading, i.e. if another createOrderItem is in progress
    if (cartLoading) return

    if (cartId) return addItemToCart()
    if (!isModalOpen('fulfillment')) {
      setShowFulfillmentForm(true)
      openModal('fulfillment')
    }
  }

  const showFromPrepend =
    !quickAddAllowed && maxPrice.base > minPrice.base && inStock

  const formatPriceText = useCallback(() => {
    const pricePrepend = showFromPrepend ? 'From' : ''
    const priceToDisplay = minPrice.base
    if (pricePrepend) return `${pricePrepend} £${priceToDisplay}`
    return `£${priceToDisplay}`
  }, [showFromPrepend, minPrice.base])

  /** Resets local state on related to fulfillment modal on modal close */
  const handleFulfillmentModalClose = () => {
    setShowFulfillmentForm(false)
    setIsProductImageClick(false)
  }

  const productUrl = `/order/store/${match.params.slug}/${productSlug}`

  return (
    <>
      {isModalOpen('fulfillment') &&
        showFulfillmentForm &&
        fulfillmentModal({
          addToCart: handleFormComplete,
          onClose: handleFulfillmentModalClose
        })}
      <Container data-testid='productCard'>
        <ProductImageContainer>
          {quickAddAllowed ? (
            <AddToCartButton
              href={productUrl}
              onClick={handleAddToCart}
              buttonState={buttonState}
            />
          ) : (
            <AddToCartButton
              href={productUrl}
              onClick={handleProductClick}
              buttonState={null}
            />
          )}
          {!inStock && <SoldOutOverlay />}
          <a href={productUrl} onClick={handleProductClick}>
            <ProductImage
              src={imageUrl}
              alt={`Order ${name} online`}
              loading='lazy'
            />
          </a>
        </ProductImageContainer>
        <a href={productUrl} onClick={handleProductClick}>
          <ProductDetails>
            <ProductTitle>{name}</ProductTitle>
            <Price>{formatPriceText()}</Price>
          </ProductDetails>
        </a>
      </Container>
    </>
  )
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const Loading = styled(LoadingIcon)(() => ({
  animation: `${rotate} 1s linear infinite`
}))

const buttonStyles = {
  position: 'absolute',
  bottom: '8px',
  right: '8px',
  height: '44px',
  width: '44px',
  borderRadius: '50%',
  opacity: 0.8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  border: 0,
  outline: 0,
  fontWeight: 'lighter',
  boxShadow: '0px 2px 4px rgba(98, 98, 98, 0.15)',
  backgroundColor: 'white',
  '&:hover, &:disabled, &:disabled:hover': {
    opacity: 1
  }
}

const AnchorWrapper = styled.a(({ theme }: any) => ({
  ...(buttonStyles as any),
  [theme.mediaQueries.viewport7]: {
    bottom: '12px',
    right: '12px'
  }
}))

const ButtonWrapper = styled.button(({ theme }: any) => ({
  ...(buttonStyles as any),
  [theme.mediaQueries.viewport7]: {
    bottom: '12px',
    right: '12px'
  }
}))

const Container = styled.div(({ theme }: any) => ({
  fontFamily: theme.fonts.normal,
  cursor: 'pointer',
  '&:hover': {
    color: theme.colors.primary
  },
  '> img': {
    width: '100%',
    marginBottom: '8px'
  },
  a: {
    textDecoration: 'none'
  },
  '> .description': {
    margin: '0',
    fontWeight: theme.fontWeights.normal,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const Price = styled.p(({ theme }: any) => ({
  fontWeight: theme.fonts.body.weight,
  fontFamily: theme.fonts.body.family,
  margin: '0',
  fontSize: theme.fontSizes[0],
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[1]
  }
}))

const ProductImageContainer = styled.div(({ theme }: any) => ({
  width: '100%',
  height: 'calc(50vw - 32px)',
  position: 'relative',
  marginBottom: '16px',
  [theme.mediaQueries.viewport7]: {
    height: '240px'
  },
  [theme.mediaQueries.viewport10]: {
    height: '280px'
  },
  [theme.mediaQueries.viewport12]: {
    height: '320px'
  }
}))

const ProductImage = styled.img(({ theme }: any) => ({
  width: '100%',
  height: '100%',
  marginBottom: theme.space[2],
  objectFit: 'cover',
  textDecoration: 'none',
  color: 'lightgray',
  borderRadius: '12px'
}))

const ProductTitle = styled.div(({ theme }: any) => ({
  fontWeight: 'bold',
  fontFamily: theme.fonts.heading.family,
  fontSize: theme.fontSizes[0],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  [theme.mediaQueries.viewport7]: {
    fontSize: theme.fontSizes[1]
  }
}))

const ProductDetails = styled.div(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.colors.textBody,
  marginBottom: '8px'
}))

export default ProductCard
