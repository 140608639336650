import { Product } from 'shop/types'
import { OpeningHour } from '../Landing/types'
import { capitalize } from 'lodash'
import { format, parse } from 'date-fns'
import { CategoryProduct } from '../Shop/Categories'

export const createOpeningHours = (schedule: OpeningHour[]) => {
  if (!schedule.length) return []

  return schedule
    .flatMap((openingTime: OpeningHour) => {
      const { dayOfWeek, shifts } = openingTime
      if (!shifts.length) return []

      const dayOfWeekCapitalized = capitalize(dayOfWeek)

      return shifts.map((shift) => {
        const startTime = appendSeconds(shift.startTime)
        const endTime = appendSeconds(shift.endTime)
        return {
          '@type': 'OpeningHoursSpecification' as const,
          dayOfWeek: dayOfWeekCapitalized,
          opens: startTime,
          closes: endTime
        }
      })
    })
    .filter(Boolean)
}

const appendSeconds = (time: string): string => {
  return format(parse(time, 'HH:mm', new Date()), 'HH:mm:ss')
}

export const createOffers = (
  product: Product,
  organization: String,
  url: String
) => {
  if (product.variants.length) {
    return product.variants.map((variant) =>
      buildOffer(
        variant.id,
        variant.name,
        variant.inStock,
        variant.price.basePrice,
        url,
        organization
      )
    )
  }

  return [
    buildOffer(
      product.defaultVariantId,
      product.name,
      product.inStock,
      product.minPrice.basePrice,
      url,
      organization
    )
  ]
}

/** Temp: Created to handle the difference between two product types, will be removed in https://slerpy.atlassian.net/browse/CHK-2169 */
export const createOffersV2 = (
  product: CategoryProduct,
  organization: String,
  url: String
) => {
  if (product.variants.length) {
    return product.variants.map((variant) =>
      buildOffer(
        variant.id,
        variant.name,
        variant.inStock,
        variant.pricing.absolute.base,
        url,
        organization
      )
    )
  }

  return [
    buildOffer(
      product.defaultVariantId,
      product.name,
      product.inStock,
      product.pricing.minimum.base,
      url,
      organization
    )
  ]
}

const buildOffer = (
  id: string,
  name: string,
  inStock: boolean,
  price: string,
  url: string,
  organization: string
) => {
  return {
    '@type': 'Offer',
    sku: id,
    name: name,
    availability: inStock
      ? 'http://schema.org/InStock'
      : 'http://schema.org/OutOfStock',
    price: price,
    priceCurrency: 'GBP',
    url: url,
    seller: {
      '@type': 'Organization',
      name: organization
    }
  }
}

export const trimUrl = (url: string) => {
  return url.replace(/\/+$/, '')
}
