import { Shop } from 'shop/pages'
import {
  AppContentLoader,
  CartLoader,
  LoaderSwitcher,
  PartnerStoreLoader,
  SlerpMerchantLoader
} from 'router/loaders'

export const ShopWrapper = (props: { merchant: string; slug: string }) => {
  return (
    <AppContentLoader>
      {(isParentLoaded) => (
        <SlerpMerchantLoader isParentLoaded={isParentLoaded}>
          {(isParentLoaded) => (
            <PartnerStoreLoader
              storeSlug={props.slug}
              isParentLoaded={isParentLoaded}
            >
              {(isParentLoaded) => (
                <CartLoader isParentLoaded={isParentLoaded}>
                  {(isParentLoaded) => (
                    <LoaderSwitcher isParentLoaded={isParentLoaded}>
                      <Shop {...props} />
                    </LoaderSwitcher>
                  )}
                </CartLoader>
              )}
            </PartnerStoreLoader>
          )}
        </SlerpMerchantLoader>
      )}
    </AppContentLoader>
  )
}
