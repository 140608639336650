import { FoodEstablishment } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useAppContent, useShop, useSlerpMerchant } from 'shop/hooks'
import { createOpeningHours } from './utils'
import { Helmet } from 'react-helmet'

interface Props {
  storeSlug: string
}

export const Metadata = ({ storeSlug }: Props) => {
  const { config, currentPartnerStore } = useShop()
  const { isSeoEnabled } = useSlerpMerchant()
  const { merchantName, bannerImage, splashImage } = useAppContent()
  const {
    name: storeName,
    address: storeAddress,
    openingHours
  } = currentPartnerStore || {}

  if (!isSeoEnabled) {
    return <></>
  }

  const {
    lineOne,
    lineTwo,
    zip,
    city,
    coordinates = { lat: 0, lng: 0 }
  } = storeAddress || {}

  const openingHoursSpecification = createOpeningHours(openingHours || [])

  return (
    <Helmet
      script={[
        helmetJsonLdProp<FoodEstablishment>({
          '@context': 'https://schema.org',
          '@type': 'FoodEstablishment',
          image: [bannerImage || splashImage],
          name: `${merchantName} - ${storeName}`,
          address: {
            '@type': 'PostalAddress',
            streetAddress: lineTwo || lineOne || '',
            addressLocality: city,
            postalCode: zip || '',
            addressCountry: 'GB'
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: coordinates.lat,
            longitude: coordinates.lng
          },
          openingHoursSpecification,
          menu: `https://${config.domain}.slerp.com/order/store/${storeSlug}`
        })
      ]}
    />
  )
}
