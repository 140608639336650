import { useEffect, useState } from 'react'
import CenterModal from 'shop/components/Modal/CenterModal'
import LoginFields from './LoginFields'
import Divider from './FormDivider'
import { Button } from 'shop/components'
import styled from '@emotion/styled'
import { useMediaQueries, useQueryParams } from 'shop/hooks'
import theme from 'shop/theme/defaultTheme'
import { overlayOpenAnim, slideUp } from '../Shop/commonStyles'
import SignupFields from './SignupFields'
import FullscreenModal from '../Modal/FullscreenModal'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

interface Props {
  merchantId?: string
  onClose: () => void
  inModal?: boolean
  isOverlay?: boolean
  isOpen?: boolean
  inFulfillmentModal: boolean
}

const LoginModal = ({
  merchantId,
  onClose,
  inModal,
  isOverlay,
  isOpen,
  inFulfillmentModal
}: Props) => {
  const { hasQueryParam, deleteQueryParam } = useQueryParams()
  const defaultToRegistration = hasQueryParam('sign-up')
  const [showRegistration, setShowRegistration] = useState(
    defaultToRegistration
  )
  const { isMobile } = useMediaQueries()
  const location = useLocation()

  useEffect(() => {
    if (hasQueryParam('login')) deleteQueryParam('login')
    if (defaultToRegistration) deleteQueryParam('sign-up')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ModalContent = (
    <Form data-testid='login-modal' inFulfillmentModal={inFulfillmentModal}>
      <AccountFields>
        {showRegistration ? (
          <SignupFields
            {...{ merchantId, inFulfillmentModal }}
            linkBackToLogin={() => setShowRegistration(false)}
          />
        ) : (
          <>
            <LoginFields {...{ merchantId, inFulfillmentModal }} />
            <Divider />
            <Button
              testId={'registrationSwitch'}
              onClick={() => setShowRegistration(!showRegistration)}
              variant='secondary'
            >
              {showRegistration ? 'Already have an account' : 'Create Account'}
            </Button>
            <StyledRouterLink
              to={{
                pathname: '/forgot-password',
                state: { from: location.pathname }
              }}
            >
              Forgot your password? Click here to reset
            </StyledRouterLink>
          </>
        )}
      </AccountFields>
    </Form>
  )

  return isMobile ? (
    <FullscreenModal onClose={onClose} withCloseButton={true}>
      {ModalContent}
    </FullscreenModal>
  ) : (
    <CenterModal
      styles={inFulfillmentModal ? overlayOpenAnim(!!isOpen) : {}}
      handleCloseModal={onClose}
      zIndex={theme?.zIndex?.accountModal}
      isOverlay={isOverlay}
      isOpen={isOpen}
      inModal={inModal}
    >
      {ModalContent}
    </CenterModal>
  )
}

const Form = styled.div(({ theme, inFulfillmentModal }: any) => ({
  animation: !inFulfillmentModal ? `${slideUp} 100ms ease-out` : 'none',
  position: 'relative',
  borderRadius: '4px',
  overflowY: 'auto',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  zIndex: theme.zIndex.accountModal,
  fontFamily: theme.fonts.normal,
  margin: '32px auto',
  maxWidth: '480px',
  width: '100%',
  padding: '0 16px',
  '> h1': {
    lineHeight: 1,
    marginBottom: '8px',
    textAlign: 'center'
  },
  [theme.mediaQueries.viewport7]: {
    maxWidth: '960px',
    width: '100%',
    height: 'auto',
    padding: '0',
    margin: '40px auto 32px',
    '> h1': {
      lineHeight: 0
    }
  }
}))

const AccountFields = styled.div(({ theme }: any) => ({
  padding: '8px',
  justifyContent: 'center',
  textAlign: 'left',
  [theme.mediaQueries.viewport7]: {
    width: '80%',
    margin: '0 auto'
  }
}))

const CommonLinkStyles = (theme: any): any => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.textMute,
  display: 'block',
  textAlign: 'center',
  marginTop: '24px',
  '&:hover': {
    cursor: 'pointer'
  }
})

const StyledRouterLink = styled(RouterLink)(({ theme }: any) => ({
  ...CommonLinkStyles(theme)
}))

export default LoginModal
