import gql from 'graphql-tag'

export const QUERY_VALID_STORES = gql`
  query getValidStores(
    $merchantId: ID!
    $fulfillmentType: String!
    $fulfillmentDate: String!
    $deliveryAddress: DeliveryAddressParams
  ) {
    getValidStores(
      merchantId: $merchantId
      fulfillmentType: $fulfillmentType
      fulfillmentDate: $fulfillmentDate
      deliveryAddress: $deliveryAddress
    ) {
      stores {
        id
        name
        earliestPickupFulfillment {
          minimumOrderValue
          orderType
          orderableAt
          fulfillmentWindow {
            from
            to
          }
        }
        earliestDeliveryFulfillment {
          minimumOrderValue
          orderType
          orderableAt
          fulfillmentWindow {
            from
            to
          }
        }
        address {
          geom {
            coordinates
          }
        }
        fulfillmentTimeslots(
          fulfillmentDate: $fulfillmentDate
          fulfillmentType: $fulfillmentType
        ) {
          startTime
          endTime
          value
        }
        slug
      }
      errors {
        message
        store {
          id
          slug
          name
        }
      }
    }
  }
`
