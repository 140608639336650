export type Location = {
  id: string
  lat: number
  lng: number
}

export enum AccountCreationType {
  Loyalty = 'loyalty',
  Account = 'account'
}
