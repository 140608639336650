import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useAccount, useMediaQueries, useModal } from 'shop/hooks'
import FullscreenModal from 'shop/components/Modal/FullscreenModal'
import { useRef, useState } from 'react'
import AddressSuggestions from './AddressSuggestions'
import AddressInputBar from './AddressInputBar'
import { CustomerAddress } from 'shop/types'
import SavedAddressItem from './SavedAddressItem'

type Props = {
  address: string
  onChange: (newAddress: string) => void
  onSelect: (newAddress: string) => void
  onClose: () => void
  customerAddresses?: CustomerAddress[]
  selectCustomerAddress: (customerAddress: CustomerAddress) => void
}

const LocationSelectModal = ({
  address,
  onChange,
  onSelect,
  onClose,
  customerAddresses,
  selectCustomerAddress
}: Props) => {
  const { isMobile } = useMediaQueries()
  const [inputValue, setInputValue] = useState(address || '')
  const [hasUserChangedInput, setHasUserChangedInput] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()
  const { openModal } = useModal()

  const hasSavedAddresss = !!customerAddresses?.length
  const showSavedAddressSection = !inputValue.length

  const showSuggestions = !!inputValue.length && hasUserChangedInput

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasUserChangedInput) setHasUserChangedInput(true)
    setInputValue(event.target.value)
  }

  const handleClearInput = () => {
    setInputValue('')
    onChange('')
    if (inputRef.current) inputRef.current.focus()
  }

  const openLoginModal = () => {
    openModal('login')
  }

  return (
    <FullscreenModal
      onClose={onClose}
      isLandingColumn={!isMobile}
      styles={{ padding: '0' }}
    >
      <AddressInputBar
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        inputRef={inputRef}
        handleClearInput={handleClearInput}
        onClose={onClose}
      />
      <ContentContainer>
        {showSavedAddressSection && (
          <>
            {hasSavedAddresss && (
              <SavedAddressTitle>Saved addresses</SavedAddressTitle>
            )}
            {customerAddresses?.map((customerAddress, index) => (
              <SavedAddressItem
                key={`saved-address-${index}`}
                customerAddress={customerAddress}
                selectCustomerAddress={selectCustomerAddress}
              />
            ))}
          </>
        )}
        {/* Do not render persisting suggestions when input is empty or if a user is yet to edit */}
        {showSuggestions && (
          <AddressSuggestions
            inputValue={inputValue}
            onChange={onChange}
            onSelect={onSelect}
          />
        )}
        {!isLoggedIn && (
          <LoginCTA withBorderDivide={showSuggestions}>
            To access saved addresses and exclusive offers, please&nbsp;
            <span onClick={openLoginModal}>Log in</span>&nbsp;or&nbsp;
            <span onClick={openLoginModal}>Sign up</span>
          </LoginCTA>
        )}
      </ContentContainer>
    </FullscreenModal>
  )
}

const ContentContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    padding: '0 16px',

    [theme.mediaQueries.viewport7]: {
      padding: '0 32px'
    }
  })
)

const SavedAddressTitle = styled.h2<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: theme.fonts['body'].family,
    padding: '24px 0 12px 0'
  })
)

const LoginCTA = styled.p<
  StyledHTMLElement & { withBorderDivide: boolean },
  Required<Theme>
>(({ theme, withBorderDivide }) => ({
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: theme.fonts['body'].family,
  color: '#59595A',
  borderTop: withBorderDivide ? '1px solid #F0F0F0' : '',
  paddingTop: '24px',
  margin: 0,

  '& span': {
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#000',
    cursor: 'pointer'
  }
}))

export default LocationSelectModal
