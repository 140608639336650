import { Product } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import { useAppContent, useShop, useSlerpMerchant } from 'shop/hooks'
import { Product as ProductProps } from 'shop/types'
import { Helmet } from 'react-helmet'
import { createOffers, trimUrl } from './utils'

interface Props {
  product: ProductProps
}

export const ProductMeta = (props: Props) => {
  const { isSeoEnabled } = useSlerpMerchant()
  const { merchantName } = useAppContent()
  const { currentPartnerStore } = useShop()
  const { name: storeName } = currentPartnerStore || {}
  const additionalImages = props.product.additionalImages.map(
    (image) => image.standard
  )
  const baseUrl = trimUrl(window.location.origin + window.location.pathname)

  if (!isSeoEnabled) {
    return <></>
  }

  return (
    <Helmet
      script={[
        helmetJsonLdProp<Product>({
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: props.product.name,
          url: baseUrl,
          image: [props.product.image.standard, ...additionalImages],
          description: props.product.description,
          sku: props.product.id,
          brand: {
            '@type': 'Brand',
            name: merchantName
          },
          offers: createOffers(
            props.product,
            `${merchantName} ${storeName}`,
            baseUrl
          )
        })
      ]}
    />
  )
}
