import React from 'react'
import styled from '@emotion/styled'
import {
  AdditionalItems,
  LoyaltyCard,
  OrderItem,
  Summary
} from 'shop/types/cart'
import {
  CartTitle,
  CartItemsV2,
  CartBreakdown,
  NoteBreakdownItem,
  CartBreakdownItem
} from './index'
import { useEffect, useLayoutEffect, useState } from 'react'
import { ExpandableContainer } from '../ExpandableContainer'
import { isEmpty } from 'lodash'
import { IoCreateOutline } from 'react-icons/io5'
import { AiOutlineGift as Gift } from 'react-icons/ai'
import { FulfillmentType } from 'shop/types'

interface Props {
  type: 'Cart' | 'OrderSummary'
  orderItems: OrderItem[]
  summary: Summary
  loyaltyCards?: LoyaltyCard[]
  orderNotes: string | null
  additionalItems: AdditionalItems
  fulfillmentType: FulfillmentType
  reference?: string
  isEditable?: boolean
  isCartLoading?: boolean
}

const CartV2 = ({
  type,
  orderItems,
  summary,
  loyaltyCards,
  orderNotes,
  additionalItems,
  fulfillmentType,
  reference,
  isEditable = false,
  isCartLoading = false
}: Props) => {
  const ref: React.RefObject<HTMLDivElement> = React.createRef()
  const [hasExpanded, setHasExpanded] = useState(false)
  const [maxHeight, setMaxHeight] = useState<number | undefined>()
  const [isSeeMoreEnabled, setIsSeeMoreEnabled] = useState(false)

  useEffect(() => {
    // for animation purposes, setting the maxHeight to fit the content
    setMaxHeight(ref.current?.scrollHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.scrollHeight, setMaxHeight])

  // Check child ref height to see if we need to show the "See more" or not
  useLayoutEffect(() => {
    if (ref.current) {
      if (ref.current?.clientHeight < ref.current.scrollHeight) {
        setIsSeeMoreEnabled(true)
      }
    }
  }, [ref])

  const handleSeeMoreClick = () => setHasExpanded(!hasExpanded)

  const { giftWrapping } = additionalItems
  const showGiftNote = !!(giftWrapping?.message && giftWrapping?.price)

  return (
    <div data-testid='cart-overview'>
      <CartTitle type={type} reference={reference} />
      {!!orderNotes && (
        <NoteBreakdownItemContainer>
          <NoteBreakdownItem
            Icon={IoCreateOutline}
            noteTitle='Note'
            note={orderNotes}
          />
        </NoteBreakdownItemContainer>
      )}
      <Section>
        <ExpandableContainer
          {...{
            hasExpanded,
            isSeeMoreEnabled,
            maxHeight,
            handleSeeMoreClick,
            containerRef: ref
          }}
        >
          <CartItemsV2 items={orderItems} />
        </ExpandableContainer>
        {showGiftNote && (
          <CartBreakdownItem
            LeftIcon={Gift}
            label={'Gift Note'}
            price={giftWrapping.price}
            children={<GiftNote>{giftWrapping.message}</GiftNote>}
            isCartLoading={isCartLoading}
            withLineBreak={false}
          />
        )}
      </Section>
      {!isEmpty(summary) && (
        <CartBreakdown
          summary={summary}
          loyaltyCards={loyaltyCards}
          fulfillmentType={fulfillmentType}
          isEditable={isEditable}
          isCartLoading={isCartLoading}
        />
      )}
    </div>
  )
}

const Section = styled.div(() => ({
  margin: '24px 0'
}))

const NoteBreakdownItemContainer = styled.div(() => ({
  margin: '18px 0 32px'
}))

const GiftNote = styled.div(() => ({
  backgroundColor: '#F5F5F5',
  padding: '12px',
  borderRadius: '12px',
  marginBottom: '24px'
}))

export default CartV2
