import styled from '@emotion/styled'
import { IconTitle } from './IconTitle'
import { MdOutlinePlace, MdOutlineLocalPhone } from 'react-icons/md'
import { useAppContent, useShop } from 'shop/hooks'
import { Text } from './commonStyles'

export const ShopAddress = () => {
  const { currentPartnerStore } = useShop()
  const {
    name: storeName,
    contactNumber: storeContactNumber,
    address: storeAddress
  } = currentPartnerStore || {}
  const { merchantName } = useAppContent()

  const { lineOne, lineTwo, zip, city } = storeAddress || {}

  return (
    <Root data-testid='shop-address-root'>
      <Section>
        <IconTitle icon={<MdOutlinePlace />}>Address</IconTitle>
        <Text>
          {lineOne && (
            <>
              {lineOne}
              <br />
            </>
          )}
          {lineTwo && (
            <>
              {lineTwo}
              <br />
            </>
          )}
          {city}
          <br />
          {zip}
        </Text>
      </Section>
      <Section>
        <IconTitle icon={<MdOutlineLocalPhone />}>
          Call {merchantName} {storeName}
        </IconTitle>
        <Text>{storeContactNumber}</Text>
      </Section>
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: '26px'
}))

const Section = styled.div(() => ({}))
