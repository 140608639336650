import React, { useCallback, useEffect, useState } from 'react'

import { useShop, useQueryParams, useReactRouter, useModal } from 'shop/hooks'
import { LoaderProps } from './types'
import { FulfillmentType, ORDER_AT_TABLE_FULFILLMENT } from 'shop/types'
import { createDefaultSlerpCartParams } from 'shop/utils'
import { useConsumerCart } from 'shop/hooks/useConsumerCart'
import CartSwitchModal from 'shop/components/Modal/CartSwitchModal'
import {
  getCurrentCartId,
  isStoreSwitched,
  saveCartToLocalStorage
} from 'shop/components/Cart/utils'
import { findDefaultFulfillment } from 'shop/utils/common'

export const CartLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    initConsumerCart,
    cartError,
    switchConsumerCartStore,
    clearConsumerCart,
    cart: consumerCart,
    getConsumerCart
  } = useConsumerCart()
  const { allStores, merchant, config, currentPartnerStore } = useShop()
  const { isModalOpen } = useModal()
  const [showCartSwitchModal, setShowCartSwitchModal] = useState(false)
  const [cartSwitchError, setCartSwitchError] = useState<string | null>(null)

  const { getQueryParam, deleteQueryParam } = useQueryParams()

  const queryParamFulfillmentType = getQueryParam(
    'fulfillment_type'
  ).toUpperCase() as FulfillmentType

  const storeName = currentPartnerStore?.name
  const cartId = getCurrentCartId(config.domain)

  const { match } = useReactRouter()

  const switchCartStore = useCallback(() => {
    const store = allStores.find((store) => store.name === storeName)
    if (!!store) {
      setIsLoading(true)
      switchConsumerCartStore(store.id)
        .then(async ({ createCart }) => {
          if (!!createCart.errors.length) {
            setCartSwitchError(createCart.errors[0].message)
            return
          }
          const newCartId = createCart.cart.id
          if (merchant.id && merchant.slug) {
            saveCartToLocalStorage(newCartId, {
              id: merchant.id,
              slug: merchant.slug
            })
          }
          setShowCartSwitchModal(false)
        })
        .catch(() => {
          console.error('failed to switch carts')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, storeName, merchant?.id, merchant?.slug])

  const clearCart = () => {
    clearConsumerCart()
    localStorage.removeItem(config.domain)
  }

  /** Gets cart on init if there is a cart id */
  useEffect(() => {
    // Can't switch cart store without store details
    if (cartId && !!allStores.length && storeName) {
      const loadCartFromId = async () => {
        if (isLoading) return
        setIsLoading(true)

        if (consumerCart?.id) {
          setIsLoading(false)
          return
        }

        try {
          await getConsumerCart().then((cart) => {
            // Deeplink change store and cart
            if (isStoreSwitched(match.params['slug'], cart)) {
              // This clashes with FulfillmentModal logic when navigating to a new store, so return out
              if (isModalOpen('fulfillment')) {
                setIsLoading(false)
                return
              }

              // just switch carts if the user has no items in their old cart
              if (!cart?.orderItems.length) {
                switchCartStore()
              } else {
                setShowCartSwitchModal(true)
                return
              }
            }

            // If our loaded cart does not match the query params, remove them to avoid user confusion.
            if (cart?.fulfillment.type !== queryParamFulfillmentType) {
              deleteQueryParam('fulfillment_type')
            }
          })
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          console.log(err)
        }
      }
      loadCartFromId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, allStores.length, storeName])

  /** Creates cart on init with default parameters if there is no cart id or previous cart error */
  useEffect(() => {
    if (cartId || cartError) return

    if (!!allStores.length && currentPartnerStore) {
      if (isLoading) return

      // Find first orderFulfillment for current store which is ACCEPTING_ORDERS
      const defaultFulfillmentInfo =
        findDefaultFulfillment(
          currentPartnerStore.orderFulfillmentTypes,
          queryParamFulfillmentType
        ) ?? null

      // If there are no fulfillments ACCEPTING_ORDERS return and no cart is created
      if (!defaultFulfillmentInfo) {
        return
      }

      const initFulfillmentType: FulfillmentType =
        queryParamFulfillmentType || defaultFulfillmentInfo.fulfillmentType

      // Table orders should redirect to landing page so return early,
      // they should ignore any query params too.
      if (
        defaultFulfillmentInfo.fulfillmentType === ORDER_AT_TABLE_FULFILLMENT
      ) {
        return
      }

      const store = allStores.find((store) => store.name === storeName)

      // if store exists but is not open, then do not create cart
      if (!store || !store?.is_open) {
        return
      }

      const storeId = store.id

      const slerpCartParams = createDefaultSlerpCartParams(
        storeId,
        initFulfillmentType,
        defaultFulfillmentInfo
      )
      if (!slerpCartParams) {
        return
      }

      // Create cart using consumer API
      initConsumerCart({ variables: slerpCartParams })
        .then((res) => {
          if (!res) {
            return
          }

          const { cart } = res || {}

          if (!cart) {
            return
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartId, allStores, currentPartnerStore])

  return (
    <React.Fragment>
      {showCartSwitchModal && (
        <CartSwitchModal
          setShowCartSwitchModal={setShowCartSwitchModal}
          switchCartStore={switchCartStore}
          clearCart={clearCart}
          storeName={storeName || ''}
          cartSwitchError={cartSwitchError}
        />
      )}
      {children(!isLoading && isParentLoaded)}
    </React.Fragment>
  )
}
