export const PICKUP_FULFILLMENT = 'PICKUP'
export const DELIVERY_FULFILLMENT = 'DELIVERY'
export const ORDER_AT_TABLE_FULFILLMENT = 'ORDER_AT_TABLE'

export type FulfillmentType =
  | typeof PICKUP_FULFILLMENT
  | typeof DELIVERY_FULFILLMENT
  | typeof ORDER_AT_TABLE_FULFILLMENT

export const ASAP_ORDER = 'ASAP'
export const SCHEDULED_ORDER = 'SAMEDAY'
export const PREORDER_ORDER = 'PREORDER'

export type OrderType =
  | typeof ASAP_ORDER
  | typeof SCHEDULED_ORDER
  | typeof PREORDER_ORDER

/**
 * Unified type to indicate the origin from which a component is being called.
 * This type helps components determine the context or logic specific to the origin
 * when receiving the origin prop.
 */
export type Origin =
  | typeof CHECKOUT_ORIGIN
  | typeof SHOP_ORIGIN
  | typeof CART_MODAL_ORIGIN

export const CHECKOUT_ORIGIN = 'CHECKOUT'
export const SHOP_ORIGIN = 'SHOP'
export const CART_MODAL_ORIGIN = 'CART_MODAL'

export type FulfillmentTime = {
  from: Date
  to: Date
}
export interface DeliveryAddress {
  flatNumber: string | null
  line_1: string
  line_2: string
  city: string
  country: string
  zip: string
}

export interface ConsumerDeliveryAddress {
  lineOne: string
  lineTwo: string
  city: string
  country: string
  zip: string
}

export type LatLng = { lat: number; lng: number }

export interface RWGToken {
  token: string
  expires_at: string
}

type GraphQLError = {
  graphQLErrors: AbsintheError[]
}

export type AbsintheError = {
  error: string
  message: string
}

export type ApiError = Error & GraphQLError

export interface CountryCode {
  name: string
  code: string
  dial_code: string
  preferred?: boolean
  flag: string
}

export interface Image {
  original: string
  standard: string
  thumb: string
}

export type FulfillmentWindow = {
  from: string
  to: string
}

export interface BasePrice {
  base: string
}
