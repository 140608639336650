/* global google */

import styled from '@emotion/styled'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { useEffect, useState, useRef } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import uuidv4 from 'uuid/v4'

type PlacesAutocompleteRef = PlacesAutocomplete & {
  fetchPredictions: () => void
}

type AddressSuggestionsProps = {
  inputValue: string
  onChange: (value: string) => void
  onSelect: (value: string) => void
}
const AddressSuggestions = ({
  inputValue,
  onChange,
  onSelect
}: AddressSuggestionsProps) => {
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(false)
  const placesAutocompleteRef = useRef<PlacesAutocompleteRef>(null)

  const searchOptions = {
    location: new google.maps.LatLng({ lat: 51.5137137, lng: -0.1285204 }),
    radius: 30,
    sessiontoken: uuidv4()
  }

  useEffect(() => {
    const strippedAddressLength = inputValue.replace(/\s/g, '')
    const isAddressEligible = strippedAddressLength.length >= 3

    if (isAddressEligible) {
      setShouldFetchSuggestions(true)
      placesAutocompleteRef.current?.fetchPredictions()
    } else {
      setShouldFetchSuggestions(false)
    }
  }, [inputValue])

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      debounce={500}
      shouldFetchSuggestions={shouldFetchSuggestions}
      highlightFirstSuggestion
      ref={placesAutocompleteRef}
      onChange={onChange}
      onSelect={onSelect}
      value={inputValue}
    >
      {(placesProps) => {
        const { getSuggestionItemProps } = placesProps
        return (
          <AutoCompleteInputGroup data-testid='address-autocomplete'>
            <div className='autocomplete-dropdown'>
              {placesProps.suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? 'suggestion-item suggestion-item-active'
                  : 'suggestion-item'

                return (
                  <div
                    data-testid={`addressSuggestion`}
                    {...{
                      ...getSuggestionItemProps(suggestion, { className }),
                      key: `address-suggestion-${i}`
                    }}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </AutoCompleteInputGroup>
        )
      }}
    </PlacesAutocomplete>
  )
}
const AutoCompleteInputGroup = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    position: 'relative',
    '.autocomplete-dropdown': {
      marginTop: '8px',
      fontSize: theme.fontSizes[1],
      color: theme.colors.textBody,
      width: '100%',
      zIndex: 10
    },
    '.suggestion-item': {
      background: '#ffffff',
      cursor: 'pointer',
      padding: '16px 12px',
      lineHeight: '16px',
      margin: '4px 0',
      color: theme.colors.body
    },
    '.suggestion-item:last-child': {
      marginBottom: '24px'
    },
    '.suggestion-item-active': {
      background: '#FAFAFA',
      borderRadius: '12px'
    }
  })
)

export default AddressSuggestions
