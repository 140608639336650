import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  LandingFormNew,
  LandingContextNew,
  Cookies,
  InvalidTimeSlot
} from 'shop/components/Landing'
import { useConsumerCart, useShop } from 'shop/hooks'
import { format, isToday, parseISO } from 'date-fns'
import {
  getValidSamedayStores,
  getValidPreorderStores,
  returnDefaultFulfillmentDate,
  createCartFulfillmentDate
} from 'shop/components/Landing/utils'
import { getFulfillmentFlags, getStoreById, parseTimezoneISO } from '../utils'

import {
  ValidStore,
  Store,
  CurrentLocationState,
  AvailableDates
} from 'shop/components/Landing/types'
import { QUERY_VALID_STORES } from 'shop/client/queries/StoreQueries'
import { sortStoresByLocation } from 'shop/components/Landing/locationUtils'
import { useTracker, TrackableEvent } from 'tracker'
import { QUERY_GET_AVAILABLE_DATES } from 'shop/client/queries/DateQueries'
import {
  formatReplaceAvailableDates,
  getCalendarStartAndEndDates,
  getEarliestAvailableDate
} from 'shop/components/Landing/datePickerUtils'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { createAddressString } from 'shop/utils/address'
import { FulfillmentTypeLoadingStates } from 'shop/components/Landing/LandingContext'
import { formatFulfillmentWindow } from 'shop/utils/common'
import { ASAP_ORDER, PREORDER_ORDER } from 'shop/types'

interface LandingProps {
  modal?: any
  addToCart?: any
  cartId?: string
  storeId?: string
  orderAgainCartId?: string
  setIsModalOpen: (value: boolean) => void
  onClose: () => void
}

export const landingContent = (
  modal: boolean,
  onClose: () => void,
  setIsModalOpen: (value: boolean) => void
) => {
  return (
    <>
      <Container>
        {!modal && !window.localStorage.getItem('closeNotice') && (
          <CookiesContainer>
            <Cookies />
          </CookiesContainer>
        )}
        <FormContainer>
          <LandingFormNew onClose={onClose} setIsModalOpen={setIsModalOpen} />
        </FormContainer>
      </Container>
      <InvalidTimeSlot />
    </>
  )
}

const LandingNew = ({
  setIsModalOpen,
  modal,
  addToCart,
  orderAgainCartId,
  storeId,
  cartId,
  onClose
}: LandingProps) => {
  const {
    config,
    useShopClient,
    merchant,
    setMerchant,
    allStores,
    refetchMerchantAndStores
  } = useShop()
  const client = useShopClient()

  // Get values from cart if available
  const { cart } = useConsumerCart()
  const { deliveryAddress } = cart || {}
  const { isDelivery } = getFulfillmentFlags(cart?.fulfillment?.type)
  const isAsap = cart?.fulfillment.orderType === ASAP_ORDER
  const windowFromDate = cart && parseTimezoneISO(cart?.fulfillment.window.from)
  const cartFulfillmentType = cart?.fulfillment.type?.toLowerCase() || ''
  const cartFulfillmentTimeRange = cart?.fulfillment.window
    ? formatFulfillmentWindow(cart?.fulfillment.window, 'p')
    : null
  const cartFulfillmentDate = createCartFulfillmentDate(
    isAsap ? null : windowFromDate,
    cart?.fulfillment.orderType === PREORDER_ORDER
  )
  const cartFulfillmentTime = isAsap ? 'immediately' : null
  const cartOrderDay = !isAsap ? 'scheduled' : 'now'

  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [stores, setStores] = useState<Store[]>([])
  const [currentStore, setCurrentStore] = useState<ValidStore>()
  const [validStores, setValidStores] = useState<ValidStore[]>([])
  const [loadingStores, setLoadingStores] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [loadingDates, setLoadingDates] =
    useState<FulfillmentTypeLoadingStates>({
      delivery: false,
      pickup: false
    })
  const [fulfillmentType, setFulfillmentType] =
    useState<string>(cartFulfillmentType)
  const [currentLocation, setCurrentLocation] = useState<CurrentLocationState>()
  const [fulfillmentDate, setFulfillmentDate] = useState<Date>(
    isDelivery ? new Date() : cartFulfillmentDate
  )
  const [fulfillmentTime, setFulfillmentTime] = useState<string | null>(
    cartFulfillmentTime
  )
  const [fulfillmentTimeRange, setFulfillmentTimeRange] = useState<
    string | null
  >(cartFulfillmentTimeRange)
  const [useCustomerAddress, setUseCustomerAddress] = useState(false)
  const [storeOutOfRange, setStoreOutOfRange] = useState<boolean>(false)
  const [sameDayOnly, setSameDayOnly] = useState<boolean>(false)
  const [preOrderOnly, setPreOrderOnly] = useState<boolean>(false)
  const [availableDates, setAvailableDates] = useState<AvailableDates>({
    pickup: {},
    delivery: {}
  })
  const [isExtendedHour, setIsExtendedHour] = useState(false)
  const [hasFailedSubmit, setHasFailedSubmit] = useState(false)
  const [showFulfillmentOptions, setShowFulfillmentOptions] = useState(false)
  const [address, setAddress] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState<boolean>(cart ? true : false)
  const [selectedOrderDay, setSelectedOrderDay] = useState(cartOrderDay)
  const [prevCartId, setPrevCartId] = useState<string | null>(null)
  const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false)

  const isPickup = fulfillmentType === 'pickup'
  const isPickupAddressEnabled: boolean =
    isPickup && !!merchant.pickup_address_enabled

  const hasAvailableDates: boolean = useMemo(() => {
    const availableMonths = Object.keys(availableDates[fulfillmentType] || {})
    if (!availableMonths.length) return false
    return true
  }, [availableDates, fulfillmentType])

  const includeAddressInVars =
    fulfillmentType === 'delivery' || isPickupAddressEnabled

  const getAvailableDates = (baseDate: Date = new Date()) => {
    if (!merchant || fulfillmentType === '') return

    setLoadingDates({ ...loadingDates, [fulfillmentType]: true })

    const { startDate } = getCalendarStartAndEndDates(baseDate)

    return client
      .query({
        query: QUERY_GET_AVAILABLE_DATES,
        fetchPolicy: 'cache-first',
        variables: {
          merchantId: merchant.id,
          fulfillmentType,
          startDate,
          lat: includeAddressInVars ? currentLocation?.latLng.lat : undefined,
          lng: includeAddressInVars ? currentLocation?.latLng.lng : undefined
        }
      })
      .then((res) => {
        setAvailableDates(
          formatReplaceAvailableDates(
            availableDates,
            res?.data?.dates || [],
            fulfillmentType
          )
        )
        setLoadingDates({ ...loadingDates, [fulfillmentType]: false })
      })
  }

  const landingState = {
    merchant,
    setMerchant,
    stores,
    setStores,
    fulfillmentType,
    setFulfillmentType,
    fulfillmentDate,
    setFulfillmentDate,
    fulfillmentTime,
    setFulfillmentTime,
    fulfillmentTimeRange,
    setFulfillmentTimeRange,
    currentLocation,
    setCurrentLocation,
    currentStore,
    setCurrentStore,
    useCustomerAddress,
    setUseCustomerAddress,
    inModal: modal,
    addToCart: addToCart,
    cartId: cartId,
    orderAgainCartId: orderAgainCartId,
    validStores,
    loadingStores,
    setLoadingStores,
    loadingDates,
    setLoadingDates,
    storeOutOfRange,
    availableDates,
    getAvailableDates,
    isExtendedHour,
    setIsExtendedHour,
    sameDayOnly,
    preOrderOnly,
    hasFailedSubmit,
    setHasFailedSubmit,
    showFulfillmentOptions,
    setShowFulfillmentOptions,
    isOverlayOpen,
    setIsOverlayOpen,
    address,
    setAddress,
    isEditMode,
    setIsEditMode,
    selectedOrderDay,
    setSelectedOrderDay,
    prevCartId,
    setPrevCartId,
    isLoginOpen,
    setIsLoginOpen
  }

  const { trackEvent } = useTracker()

  useEffect(() => {
    trackEvent(TrackableEvent.LandingPageViewed)
  }, [trackEvent])

  // Set current location when delivery address is available (for edit cart flow)
  useEffect(() => {
    let shouldUpdate: boolean
    if (cart && deliveryAddress) {
      shouldUpdate = true
      const { lineOne, lineTwo, city, country, zip } = deliveryAddress
      let addressString: string

      // If generic non-geocoded address is set in delivery address from BE, use address string to get more accurate location for UI
      const onlyCountry = !lineOne && !lineTwo && !city && country && !zip
      if (onlyCountry && address) {
        addressString = address
      } else {
        addressString = deliveryAddress
          ? createAddressString(deliveryAddress)
          : address
      }
      if (!addressString) {
        return
      }

      geocodeByAddress(addressString)
        .then(async (results) => {
          if (shouldUpdate) {
            const latLng = await getLatLng(results[0])
            const formattedAddress = results[0].formatted_address
            const addressComponents = results[0].address_components
            setCurrentLocation({
              address: formattedAddress,
              addressComponents,
              latLng
            })
            setAddress(addressString)
            setFulfillmentDate(cartFulfillmentDate)
          }
        })
        .catch((error) => console.error('Error', error))
    }
    return () => {
      shouldUpdate = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryAddress])

  // Update the stores state with the useShop allStores
  useEffect(() => {
    setStores(allStores)
  }, [allStores])

  // On load, ensure merchant and store data is up to date.
  useEffect(() => {
    refetchMerchantAndStores()
  }, [config.domain]) // eslint-disable-line

  // Use fulfillmentDate if empty
  useEffect(() => {
    if (!fulfillmentDate) {
      setFulfillmentDate(new Date())
    }
  }, [fulfillmentDate])

  const clearSelectedTime = () => {
    setFulfillmentTime(null)
    setFulfillmentTimeRange(null)
  }

  const getValidStores = () => {
    const deliveryAddress =
      fulfillmentType === 'delivery' && currentLocation?.latLng
        ? {
            geom: {
              lat: currentLocation.latLng.lat.toString(),
              long: currentLocation.latLng.lng.toString()
            },
            type: 'GEOCODE'
          }
        : null
    return client
      .query({
        query: QUERY_VALID_STORES,
        variables: {
          merchantId: merchant.id,
          fulfillmentType,
          fulfillmentDate: format(fulfillmentDate, 'yyyy-MM-dd'),
          deliveryAddress
        }
      })
      .then(({ data: { getValidStores } }) => {
        const { stores } = getValidStores[0]
        const filteredStores = stores.filter(
          (store: ValidStore) => store.fulfillmentTimeslots.length > 0
        )
        if (currentLocation) {
          return sortStoresByLocation(filteredStores, currentLocation)
        }
        return filteredStores
      })
  }

  /**
   * When switching fulfillmentType set to earliest available date,
   * if no available date, call getAvailableDates
   */
  useEffect(() => {
    // set to earliest available date of already fetched available dates.
    if (hasAvailableDates) {
      const earliestDate = getEarliestAvailableDate(
        availableDates,
        fulfillmentType
      )
      if (isEditMode) {
        setFulfillmentDate(
          returnDefaultFulfillmentDate(
            isEditMode,
            cart,
            fulfillmentDate,
            earliestDate
          )
        )
      } else {
        setFulfillmentDate(earliestDate)
      }
      return
    }
    // otherwise call getAvailableDates
    if (fulfillmentType === 'delivery' && currentLocation) getAvailableDates()
    if (fulfillmentType === 'pickup') getAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentType])

  /**
   * Whenever a user changes location, fetch new available dates
   */
  useEffect(() => {
    if (currentLocation) getAvailableDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation])

  // whenever we finish fetching available dates, set to earliest fulfillment date
  useEffect(() => {
    // no dates mean no stores
    if (!hasAvailableDates) setValidStores([])
    if (!loadingDates.delivery && !loadingDates.pickup && hasAvailableDates) {
      const earliestDate = getEarliestAvailableDate(
        availableDates,
        fulfillmentType
      )
      if (earliestDate) {
        if (isEditMode) {
          setFulfillmentDate(
            returnDefaultFulfillmentDate(
              isEditMode,
              cart,
              fulfillmentDate,
              earliestDate
            )
          )
        } else {
          setFulfillmentDate(earliestDate)
        }
        return
      }
      setFulfillmentDate(new Date())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDates])

  useEffect(() => {
    const hasAddress =
      fulfillmentType === 'delivery' ? !!currentLocation?.address : true

    if (
      merchant &&
      fulfillmentDate &&
      fulfillmentType &&
      hasAddress &&
      hasAvailableDates
    ) {
      setLoadingStores({ ...loadingStores, [fulfillmentType]: true })
      setValidStores([])
      getValidStores()
        .then((stores) => {
          setValidStores(stores || [])
          setDefaultStore(stores)
          // if editCart is active set store id to currently selected store in cart
          if (isEditMode) {
            const currentStore = stores.find(
              (store: any) => store.id === cart?.store?.id
            )
            currentStore && setCurrentStore(currentStore)
          }
        })
        .catch(console.error)
        .finally(() => {
          if (cart && isEditMode && prevCartId !== cart.id) {
            setPrevCartId(cart.id)
          } else {
            clearSelectedTime()
          }
          setLoadingStores({ ...loadingStores, [fulfillmentType]: false })
        })
    }
  }, [fulfillmentDate, availableDates[fulfillmentType]]) // eslint-disable-line

  // If isPickupAddressEnabled is true & validStores changes, ensures closest store is chosen on location change (first store in list)
  useEffect(() => {
    const [firstStore] = validStores
    const previousStore = validStores.find(
      (store) => store.id === currentStore?.id
    )
    if (isPickupAddressEnabled) {
      if (previousStore && previousStore !== firstStore) {
        setCurrentStore(previousStore)
        return
      } else {
        setCurrentStore(firstStore)
        return
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validStores])

  const setDefaultStore = (stores: ValidStore[]) => {
    if (orderAgainCartId) {
      const storeById = getStoreById(stores, storeId)
      setCurrentStore(storeById)
    } else {
      const [firstStore] = stores
      // If previous store is available on the new date, keep current store else set first store
      const previousStore = stores.find(
        (store) => store.id === currentStore?.id
      )
      if (previousStore) {
        setCurrentStore(previousStore)
      } else if (firstStore) {
        setCurrentStore(firstStore)
      }
    }
  }

  useEffect(() => {
    if (merchant && fulfillmentType) {
      const formattedDate = parseISO(fulfillmentDate.toISOString())

      if (isToday(formattedDate)) {
        const { storeOutOfRange: sameDayStoreOutOfRange } =
          getValidSamedayStores(
            stores,
            fulfillmentType,
            formattedDate,
            currentLocation
          )
        setStoreOutOfRange(sameDayStoreOutOfRange)
      } else {
        const { storeOutOfRange: preOrderStoreOutOfRange } =
          getValidPreorderStores(
            stores,
            fulfillmentType,
            formattedDate,
            currentLocation
          )
        setStoreOutOfRange(preOrderStoreOutOfRange)
      }
    }
  }, [currentLocation, fulfillmentType, fulfillmentDate, stores, merchant])

  useEffect(() => {
    if (merchant && stores) {
      const { validStores: availableSameDayStores } = getValidSamedayStores(
        stores,
        fulfillmentType,
        new Date(),
        currentLocation
      )
      const { validStores: availableStoresForPreorder } =
        getValidPreorderStores(
          stores,
          fulfillmentType,
          undefined,
          currentLocation
        )

      setSameDayOnly(false)
      setPreOrderOnly(false)

      if (
        availableStoresForPreorder.length > 0 &&
        availableSameDayStores.length === 0
      ) {
        setPreOrderOnly(true)
      }
      if (
        availableStoresForPreorder.length === 0 &&
        availableSameDayStores.length > 0
      ) {
        setSameDayOnly(true)
      }
    }
  }, [currentLocation, stores, merchant, fulfillmentType, isEditMode])

  // Attempts to clear timeslot upon store change
  useEffect(() => {
    if (fulfillmentTime && !loadingStores) clearSelectedTime()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore])

  return (
    <LandingContextNew.Provider value={landingState}>
      {landingContent(modal, onClose, setIsModalOpen)}
    </LandingContextNew.Provider>
  )
}

const Container = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    position: 'relative',
    height: '100%',
    borderRadius: '24px',
    minHeight: '350px',
    '& > *:only-child': {
      minHeight: '100%',
      width: '100%',
      margin: 0,
      borderRadius: '24px'
    }
  })
)

const CookiesContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    zIndex: 15,
    left: 0,
    right: 0,
    top: 0,
    position: 'relative',
    [theme.mediaQueries.viewport7]: {
      padding: '24px',
      left: '45%',
      width: '50%',
      top: 'auto',
      position: 'fixed',
      bottom: 0
    },
    [theme.mediaQueries.viewport9]: {
      left: '40%'
    }
  })
)

const FormContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }: any) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    [theme.mediaQueries.viewport7]: {
      padding: '0',
      width: '45%',
      backgroundColor: 'white'
    },
    [theme.mediaQueries.viewport9]: {
      width: '40%'
    }
  })
)

export default LandingNew
