import { differenceInDays, format, startOfDay } from 'date-fns'
import ReactGA4 from 'react-ga4'
import { FulfillmentType } from 'shop/types'
import AllTrackers from 'tracker/AllTrackers'

/** Track GA4 Slerp-Custom "save_card_details"
 */
export const trackGA4CustomSaveCardDetails = (
  customerId: string,
  merchantId: string,
  saveCardDetails: boolean
) => {
  if (AllTrackers.getInstance().slerpGA4Tracker.isTrackingEnabled) {
    ReactGA4.gtag('event', 'save_card_details', {
      customer_id: customerId,
      merchant_id: merchantId,
      card_saved: saveCardDetails,
      send_to: AllTrackers.getInstance().slerpGA4Tracker.trackingId
    })
  }
}

/** Track GA4 Custom "fulfillment_type_changed" */
export const trackGA4CustomFulfillmentTypeChanged = (
  fulfillmentType: FulfillmentType,
  location: string,
  tracker: string
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'fulfillment_type_changed', {
      fulfillment_type: fulfillmentType,
      location,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "post_code_changed" */
export const trackGA4CustomPostCodeChanged = (
  location: string,
  tracker: string
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'post_code_changed', {
      location,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "order_day_changed" */
export const trackGA4CustomOrderDayChanged = (
  orderDay: string,
  location: string,
  tracker: string
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'order_day_changed', {
      location,
      orderDay,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "date_changed" */
export const trackGA4CustomDateChanged = (
  location: string,
  date: Date,
  tracker: string
) => {
  // Find number of days in advance order is scheduled for pickup/delivery
  const daysInAdvance = differenceInDays(date, startOfDay(new Date()))
  const dayOfWeek = format(date, 'EEEE')
  // Only add number_of_days_in_future if daysInAdvance is a positive int
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'date_changed', {
      location,
      date: format(date, 'dd/MM/yy'),
      ...(!!daysInAdvance && { number_of_days_in_future: daysInAdvance }),
      day_of_week: dayOfWeek,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "store_changed" */
export const trackGA4CustomStoreChanged = (
  storeId: string,
  storeName: string,
  location: string,
  tracker: string
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'store_changed', {
      store_id: storeId,
      store_name: storeName,
      location,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "timeslot_changed" */
export const trackGA4CustomTimeslotChanged = (
  timeslot: string,
  location: string,
  tracker: string
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'timeslot_changed', {
      timeslot,
      location,
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "fulfillment_modal_opened" */
export const trackGA4CustomFulfillmentModalOpened = (tracker: string) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'fulfillment_modal_opened', {
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "deeplink_click_create_new_cart" */
export const trackGA4CustomDeeplinkClickCreateNewCart = (tracker: string) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'deeplink_click_create_new_cart', {
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "deeplink_click_back_to_old_cart" */
export const trackGA4CustomDeeplinkClickBackToOldCart = (tracker: string) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', 'deeplink_click_back_to_old_cart', {
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}

/** Track GA4 Custom "view_browse_page" OR "view_search_page" */
export const trackGA4CustomToggleLandingView = (
  tracker: string,
  viewToSwitchTo: 'browse' | 'search'
) => {
  if (AllTrackers.getInstance()[tracker].isTrackingEnabled) {
    ReactGA4.gtag('event', `view_${viewToSwitchTo}_page`, {
      send_to: AllTrackers.getInstance()[tracker].trackingId
    })
  }
}
