import { parseISO, parse, startOfDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const DEFAULT_TIMEZONE = 'Europe/London'

export const timezoneNow = (timezone = DEFAULT_TIMEZONE): Date => {
  const now = new Date()
  return utcToZonedTime(now.toISOString(), timezone)
}

export const parseTimezoneISO = (
  iso: string,
  timezone = DEFAULT_TIMEZONE
): Date => {
  return utcToZonedTime(parseISO(iso).toISOString(), timezone)
}

export const timeToDatetime = (time: string, timezone = DEFAULT_TIMEZONE) => {
  return parse(time, 'HH:mm', timezoneNow(timezone))
}

// Bec safari 💩
export const createDate = (date: string | Date | null): Date => {
  if (!date) return parseISO(new Date().toISOString())
  if (typeof date === 'string') return parseISO(date)
  return parseISO(date.toISOString())
}

export const zonedToLocalStartOfDay = (date: Date | null) => {
  if (!date) return null

  const localDate = parseISO(date.toISOString())
  const dayStartZoned = startOfDay(localDate)

  return dayStartZoned
}
