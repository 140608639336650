import {
  isSameDay,
  isAfter,
  getMinutes,
  isBefore,
  setHours,
  setMinutes,
  setSeconds,
  addMinutes,
  format,
  formatISO,
  startOfHour,
  startOfDay,
  subHours,
  subMinutes,
  isToday,
  parseISO,
  differenceInMinutes,
  addSeconds,
  addDays
} from 'date-fns'
import {
  Store,
  OperatingSchedule,
  OperatingSchedules,
  SplitHour,
  AvailableDates,
  ValidStore
} from './types'
import { groupBy, isEmpty } from 'lodash'
import { enUS } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'
import {
  doesStoreAcceptPreordersForDate,
  getStorePreOrderSettings
} from './preOrderUtils'
import { isAccessible } from './deliveryRadiusUtils'
import { CurrentLocationState, OrderFulfillmentType } from '..'
import { ConsumerCart } from 'shop/types/cart'
import { ASAP_ORDER, FulfillmentType } from 'shop/types'
import {
  trackGA4CustomDateChanged,
  trackGA4CustomFulfillmentTypeChanged,
  trackGA4CustomOrderDayChanged,
  trackGA4CustomPostCodeChanged,
  trackGA4CustomStoreChanged,
  trackGA4CustomTimeslotChanged
} from 'tracker/GA/custom'

interface TimeSlot {
  range: string
  value: string
  extended?: boolean | undefined
}

/** Returns a string of the date in YYYY-MM-DD format faithtful user's timezone */
export const dateString = (fulfillmentDate: Date): string => {
  const offsetDate = new Date(
    fulfillmentDate.getTime() - fulfillmentDate.getTimezoneOffset() * 60 * 1000
  )
  return offsetDate.toISOString().split('T')[0]
}

/** Creates a string of the provided fulfilment time in HH:MM format */
export const timeString = (fulfillmentTime: string | null): string | null => {
  return fulfillmentTime ? fulfillmentTime.slice(0, 5) : null
}

export const timeStringToDateTime = (
  date: Date,
  timeString?: string | null
) => {
  if (!timeString) return null
  const [hour, minutes] = timeString.split(':')

  return setMinutes(setHours(date, Number(hour)), Number(minutes))
}

const addLeadingZero = (integer: number) => {
  return integer < 10 ? `0${integer}` : `${integer}`
}

export const toBST = (date: Date) => {
  const bstDate = utcToZonedTime(date, 'Europe/London')
  return format(bstDate, 'h:mm aa', { locale: enUS })
}

const toUTCTime = (date: Date) => {
  const utcHour = date.getUTCHours()
  const utcMinute = addLeadingZero(date.getUTCMinutes())
  const period = utcHour >= 12 ? 'PM' : 'AM'

  const hour = utcHour > 12 ? utcHour - 12 : utcHour

  return `${hour}:${utcMinute} ${period}`
}

export const forceConvertToBST = (date: Date) => {
  // Note: This does note take into account the  DST
  return subHours(date, date.getTimezoneOffset() / 60)
}

export const timeSlotsBuilder = (
  operatingSchedules?: OperatingSchedules | FormattedSplitSchedules | null
) => {
  if (operatingSchedules) {
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
      operatingSchedules

    return [sunday, monday, tuesday, wednesday, thursday, friday, saturday]
  }

  const daysInAWeek = 7
  return new Array(daysInAWeek).fill(defaultOperatingHours())
}

const defaultOperatingHours = () => ({
  id: null,
  enabled: false,
  end: null,
  start: null,
  special_date: null
})

export const createTimeIntervals = (
  start: string | null,
  end: string | null,
  interval: number,
  currentDate: Date,
  asapWindow: number,
  asapEnabled?: boolean
) => {
  const [startHour, startMinutes] = start ? start.split(':') : [null, null]
  const [endHour, endMinutes] = end ? end.split(':') : [null, null]

  // Store Opening time
  const openingTime =
    startHour && startMinutes
      ? setSeconds(
          setMinutes(
            setHours(
              currentDate,
              parseInt(startHour, 10) - currentDate.getTimezoneOffset() / 60
            ),
            parseInt(startMinutes, 10)
          ),
          0
        )
      : null

  // Store closing time
  const closingTime =
    endHour && endMinutes
      ? setSeconds(
          setMinutes(
            setHours(
              currentDate,
              parseInt(endHour, 10) - currentDate.getTimezoneOffset() / 60
            ),
            parseInt(endMinutes, 10)
          ),
          0
        )
      : null

  // First timeslot available
  const startTime = getClosestTimeSlot(
    openingTime,
    closingTime,
    currentDate,
    interval,
    asapWindow
  )

  let intervals: TimeSlot[] = []
  let referenceTime = startTime

  while (referenceTime && closingTime && isBefore(referenceTime, closingTime)) {
    const newInterval = {
      value: formatISO(referenceTime),
      range: createUTCHourRange(referenceTime, interval)
    }

    intervals = [...intervals, newInterval]

    referenceTime = addMinutes(referenceTime, interval)
  }

  const asapRange =
    isToday(currentDate) && asapEnabled
      ? [{ value: 'immediately', range: 'ASAP' }]
      : []

  return [...asapRange, ...intervals]
}

const getNextTime = (
  interval: number,
  currentDate: Date,
  asapWindow: number
) => {
  const dateNow = addMinutes(currentDate, asapWindow)
  const minutes = getMinutes(dateNow)
  const addedMinutes = Math.ceil(minutes / interval) * interval
  return addMinutes(startOfHour(dateNow), addedMinutes)
}

export const getClosestTimeSlot = (
  openingTime: Date | null,
  closingTime: Date | null,
  currentDate: Date,
  interval: number,
  asapWindow: number
) => {
  const startOfDate = startOfDay(utcToZonedTime(currentDate, 'Europe/London'))

  const startTime =
    openingTime &&
    isSameDay(startOfDate, openingTime) &&
    isAfter(startOfDate, openingTime)
      ? getNextTime(interval, currentDate, asapWindow)
      : openingTime

  return startTime
}

const createUTCHourRange = (time: Date, interval: number) => {
  const endTime = addMinutes(time, interval)

  return `${toUTCTime(time)} - ${toUTCTime(endTime)}`
}

export const getStorePrepTime = (
  storeSettings: any,
  fulfillmentType: string
) => {
  const prepTime = getPrepTime(storeSettings)

  return prepTime < 30
    ? getPrepTimeForFulfillment(prepTime, fulfillmentType)
    : prepTime
}

export const getPrepTime = (storeSettings: any) => {
  const {
    prep_mode,
    quiet_preparation_time,
    moderate_preparation_time,
    busy_preparation_time
  } = storeSettings
  if (prep_mode === 'quiet') return quiet_preparation_time
  if (prep_mode === 'moderate') return moderate_preparation_time
  if (prep_mode === 'busy') return busy_preparation_time
}

const getPrepTimeForFulfillment = (
  prepTime: number,
  fulfillmentType: string
) => {
  if (fulfillmentType === 'delivery') {
    return 30
  } else {
    return prepTime
  }
}

export const prepTimeRange = (
  eta: number,
  fulfillmentType: string,
  storePrepTime: number = 0
) => {
  const deliveryTimeOffset = 10
  const timeFloat = eta + storePrepTime
  const prepTime = Math.ceil(timeFloat / 5) * 5
  const newPrepTime = prepTime >= 30 ? prepTime : 30
  const prepTimeRangeDisplay = `${newPrepTime} - ${
    newPrepTime + deliveryTimeOffset
  } mins`
  const prepTimeMessage =
    fulfillmentType === 'delivery'
      ? `ETA ${prepTimeRangeDisplay}`
      : prepTimeRangeDisplay
  return prepTimeMessage
}

export const buildTimeString = (timeString: string) => {
  const [hour, minute] = timeString.split(':')
  const parsedHour = parseInt(hour, 10)

  if (parsedHour === 12) {
    return `${parsedHour}:${minute} PM`
  } else if (parsedHour > 12) {
    return `${parsedHour - 12}:${minute} PM`
  } else {
    return `${parsedHour}:${minute} AM`
  }
}

export const isStoreOperatingForDate = (
  fulfillmentDate: Date,
  splitHours: SplitHour[] | null | undefined
) => {
  if (!splitHours) {
    return false
  }

  const schedule = splitHours.find(
    ({ day_of_week, closed, enabled }) =>
      !closed &&
      enabled &&
      format(fulfillmentDate, 'EEEE').toLowerCase() ===
        day_of_week.toLowerCase()
  )

  return Boolean(schedule)
}

export const hasValidStore = (
  stores: Store[],
  fulfillmentType: string
): boolean => {
  if (!stores || stores.length === 0) return false
  return !!stores.find((store) => {
    const sameDayEnabled =
      (store.settings[`same_day_${fulfillmentType}`] ||
        store.settings[`scheduled_${fulfillmentType}`]) &&
      store.is_open
    if (sameDayEnabled) return true

    const preorderSettings = getStorePreOrderSettings(store)
    if (!preorderSettings) return false

    return preorderSettings[`${fulfillmentType}_enabled`]
  })
}

export const isASAPTimeSlotValid = (
  fulfillmentType: string,
  store?: Store,
  currentDate?: Date
) => {
  const today = currentDate || new Date()

  // 10 minutes threshold
  const THRESHOLD = 10
  const prepTime = store ? getStorePrepTime(store.settings, fulfillmentType) : 0
  const closingWindow = THRESHOLD + parseInt(prepTime, 10)

  // Every store should have split hours
  if (store?.split_hours)
    return asapWithinSplitHours(today, closingWindow, store)

  return false
}

// This checks if fulfillment date is within the store's split hours,
// you'll need to pass closingWindow generated from the store's preptime
const asapWithinSplitHours = (
  fulfillment: Date,
  closingWindow: number,
  store: Store
) => {
  if (!store) return false
  const weeklyShifts = timeSlotsBuilder(
    formatSplitHoursBreakdown(store.split_hours)
  )
  const yesterday = addDays(fulfillment, -1)
  const yesterdayShifts = (weeklyShifts[yesterday.getDay()] || []).filter(
    (shift: TimeSlot) => shift.extended
  )
  const currentShifts = weeklyShifts[fulfillment.getDay()] || []
  const possibleShifts = [...yesterdayShifts, ...currentShifts]

  const currentSlot = possibleShifts.find(
    (shift: OperatingScheduleExtended) => {
      if (!shift) return false

      const openingDateTime = shift.extended
        ? timeStringToDateTime(yesterday, shift.start)
        : timeStringToDateTime(fulfillment, shift.start)
      const closingDateTime = timeStringToDateTime(fulfillment, shift.end)

      if (!openingDateTime || !closingDateTime) return false

      const lastClosing = addMinutes(closingDateTime, -closingWindow)

      return (
        isAfter(fulfillment, openingDateTime) &&
        isBefore(fulfillment, lastClosing)
      )
    }
  )
  return (
    !!currentSlot &&
    !!currentSlot.start &&
    !!currentSlot.end &&
    currentSlot.enabled
  )
}

type FormattedSplitSchedules = {
  monday: OperatingSchedule & { extended: boolean }
  tuesday: OperatingSchedule & { extended: boolean }
  wednesday: OperatingSchedule & { extended: boolean }
  thursday: OperatingSchedule & { extended: boolean }
  friday: OperatingSchedule & { extended: boolean }
  saturday: OperatingSchedule & { extended: boolean }
  sunday: OperatingSchedule & { extended: boolean }
}

type OperatingScheduleExtended = OperatingSchedule & { extended: boolean }

export const formatSplitHours = (
  split_hours: SplitHour[],
  fulfillmentDate: Date
) => {
  const groupedShifts = groupBy(split_hours, (shift) => shift.day_of_week)

  return Object.keys(groupedShifts).reduce((acc, day) => {
    const shifts: SplitHour[] = groupedShifts[day]
    const sortedShifts = shifts
      .filter((shift: SplitHour) => shift.enabled)
      .filter(
        (shift: SplitHour) =>
          !shift.special_date ||
          format(new Date(fulfillmentDate), 'Y-MM-dd') === shift.special_date
      )
      .sort((shiftA: SplitHour, shiftB: SplitHour) => {
        const [startA] = shiftA.start_time.split(':')
        const [startB] = shiftB.start_time.split(':')
        return Number(startA) - Number(startB)
      })

    if (sortedShifts.length === 0) return { ...acc, [day]: { enabled: false } }

    const firstShift = sortedShifts[0]
    const lastShift = sortedShifts[sortedShifts.length - 1]

    const start = firstShift.start_time
    const [hours, minutes] = lastShift.start_time.split(':')
    const endDateTime = addSeconds(
      setSeconds(
        setMinutes(setHours(new Date(), Number(hours)), Number(minutes)),
        0
      ),
      lastShift.seconds_length
    )

    const today = new Date()

    const end = format(endDateTime, 'HH:mm:ss')
    const extended = today.toDateString() !== endDateTime.toDateString()

    return { ...acc, [day]: { start, end, extended, enabled: true } }
  }, {}) as FormattedSplitSchedules
}

export const formatSplitHoursBreakdown = (split_hours: SplitHour[]) => {
  const groupedShifts = groupBy(split_hours, (shift) => shift.day_of_week)

  return Object.keys(groupedShifts).reduce((acc, day) => {
    const shifts = groupedShifts[day]
    const sortedShifts = shifts
      .filter((shift: SplitHour) => shift.enabled)
      .sort((shiftA: SplitHour, shiftB: SplitHour) => {
        const [startA] = shiftA.start_time.split(':')
        const [startB] = shiftB.start_time.split(':')
        return Number(startA) - Number(startB)
      })

    if (sortedShifts.length === 0)
      return { ...acc, [day]: [{ enabled: false }] }

    const dayShifts: {
      start: string
      end: string
      extended: boolean
      enabled: boolean
    }[] = sortedShifts.map((shift) => {
      const start = shift.start_time
      const [hours, minutes] = shift.start_time.split(':')
      const endRaw = addSeconds(
        setSeconds(
          setMinutes(setHours(new Date(), Number(hours)), Number(minutes)),
          0
        ),
        shift.seconds_length
      )
      const end = format(endRaw, 'HH:mm:ss')
      const extended = !isToday(endRaw)
      return { start, end, extended, enabled: true }
    })

    return { ...acc, [day]: dayShifts }
  }, {}) as FormattedSplitSchedules
}

// Built for delivery only
export const isDateTimeValidForClosing = (
  store: Store,
  splitHours: FormattedSplitSchedules | null,
  fulfillmentType: string,
  formattedDate: Date
) => {
  if (store && store.is_open && formattedDate && !isEmpty(splitHours)) {
    // store must either have asap enabled or have same day preorders enabled
    if (!store.settings.asap_enabled && store.settings.asap_only) {
      return false
    }

    const storeSchedules = timeSlotsBuilder(splitHours)
    const storeSchedule = storeSchedules[formattedDate.getDay()]

    if (!storeSchedule) {
      return false
    }

    const storeClosingTime = storeSchedule.end

    // No closing time returns invalid store
    if (!storeClosingTime || !storeSchedule.enabled) return false

    const [hour, minutes] = storeClosingTime.split(':')

    const prepTime = store ? getPrepTime(store.settings) : 0

    const closingEndDateTime = setMinutes(
      setHours(
        addDays(formattedDate, storeSchedule.extended ? 1 : 0),
        Number(hour)
      ),
      Number(minutes)
    )

    const deliveryInterval = store.settings.delivery_interval
    const pickupInterval = store.settings.pickup_interval

    const closingStartDateTime = subMinutes(
      closingEndDateTime,
      fulfillmentType === 'delivery' ? deliveryInterval : pickupInterval
    )

    return differenceInMinutes(closingStartDateTime, formattedDate) >= prepTime
  }
  return false
}

export const getValidSamedayStores = (
  stores: Store[],
  fulfillmentType: string,
  fulfillmentDate: Date,
  currentLocation?: CurrentLocationState
) => {
  let storeOutOfRange: boolean = false
  // start from midnight to get full day
  // when ASAP is available but there are no timeslots, sameday stores break :/
  fulfillmentDate.setHours(0, 0, 0, 0)
  const filteredStores = stores.filter((store) => {
    const splitHours =
      store.split_hours && formatSplitHours(store.split_hours, fulfillmentDate)
    const isOperatingForTime = (() => {
      if (!store.is_open) return false

      const withinClosingTime = isDateTimeValidForClosing(
        store,
        splitHours,
        fulfillmentType,
        fulfillmentDate
      )
      const operatingOnDate = isStoreOperatingForDate(
        fulfillmentDate,
        store?.split_hours
      )

      if (!operatingOnDate) return false

      if (store.settings.asap_enabled && withinClosingTime) {
        return true
      }

      if (withinClosingTime) return true
      return false
    })()

    if (
      fulfillmentType === 'delivery' &&
      currentLocation &&
      !isAccessible(store, currentLocation, fulfillmentType, false)
    ) {
      storeOutOfRange = true
      return false
    }
    return (
      (store.settings[`same_day_${fulfillmentType}`] ||
        store.settings[`scheduled_${fulfillmentType}`]) &&
      isOperatingForTime
    )
  })

  return {
    storeOutOfRange,
    validStores: filteredStores
  }
}

export const getValidPreorderStores = (
  stores: Store[],
  fulfillmentType: string,
  fulfillmentDate?: Date,
  currentLocation?: CurrentLocationState,
  currentDate: Date = new Date() // for testing only
) => {
  let storeOutOfRangeCounter = 0
  const filteredStores = stores.filter((store) => {
    const settings = getStorePreOrderSettings(store)
    if (!settings) return false
    if (
      fulfillmentType === 'delivery' &&
      currentLocation &&
      !isAccessible(store, currentLocation, fulfillmentType, true)
    ) {
      storeOutOfRangeCounter += 1
      return false
    }
    if (!settings[`${fulfillmentType}_enabled`]) return false

    if (fulfillmentDate) {
      return doesStoreAcceptPreordersForDate(
        fulfillmentDate,
        store,
        fulfillmentType,
        currentDate
      )
    } else {
      return settings[`${fulfillmentType}_enabled`]
    }
  })
  return {
    // only return 'storeOutOfRange' when every store is outside of the delivery area.
    storeOutOfRange: storeOutOfRangeCounter === stores.length,
    validStores: filteredStores
  }
}

export const getInvalidStoresMessage = (
  fulfillmentDate: Date,
  storeOutOfRange: boolean = false,
  isPickupAvailable: boolean = false,
  newFulfillmentModal: boolean = false
): string => {
  if (newFulfillmentModal) {
    if (isToday(fulfillmentDate)) {
      if (storeOutOfRange) {
        return `Unfortunately, there is no store close to you. Please change ${
          isPickupAvailable ? ' to collection, or change' : ''
        } your address`
      }
      return `Unfortunately, our store is now closed, please select a future date or visit on another day`
    }
    if (storeOutOfRange) {
      return `Unfortunately, there is no store close to you. ${
        isPickupAvailable ? ' Please select collection.' : ''
      }`
    }
    return `Unfortunately, orders are not available for this day. Please select another date.`
  } else {
    if (isToday(fulfillmentDate)) {
      if (storeOutOfRange)
        return `Unfortunately we do not currently deliver to your area. Please select ${
          isPickupAvailable ? 'Pickup or ' : ''
        }a future date.`
      return `Sorry, our store is now closed. Please select a future date or
      visit us on another day.`
    }
    if (storeOutOfRange)
      return `Unfortunately we do not currently deliver to your area.${
        isPickupAvailable ? ' Please select Pickup.' : ''
      }`
    return `Unfortunately, orders are not available for this day. Please select another date.`
  }
}

/**
 *
 * @param expectedStep Represents the step number that is expected without any optional steps.
 * @param offsetOptions Logic to handle the addition of optional steps.
 * @param manualOffset Offset the calculated number manually.
 * @returns Title string to be used for steps. e.g. "1." "2." "3."
 */
export const calculateStepNumberText = (
  expectedStep: number,
  offsetOptions?: {
    showFulfillmentOptions?: boolean
    pickup_address_enabled?: boolean
  },
  manualOffset: number = 0
): string => {
  let offset = 0
  if (offsetOptions?.showFulfillmentOptions) {
    offset += 1
  }
  if (expectedStep >= 2 && offsetOptions?.pickup_address_enabled) {
    offset += 1
  }
  return `${expectedStep + offset + manualOffset}.`
}

export interface OrderDayMapping {
  delivery: OrderDayItems
  pickup: OrderDayItems
}

export interface OrderDayItems {
  now: {
    value: string
    disabled: boolean
  }
  scheduled: {
    value: string
    disabled: boolean
  }
}

/** Initial order day map for displaying friendly dropdown values for delivery & pickup immediate & scheduled */
export const initialSelectedOrderDayMapping: OrderDayMapping = {
  delivery: {
    now: { value: 'Deliver now', disabled: false },
    scheduled: { value: 'Schedule a delivery', disabled: false }
  },
  pickup: {
    now: { value: 'Pick up now', disabled: false },
    scheduled: { value: 'Schedule a collection', disabled: false }
  }
}

/** Returns order day options for UI display based on fulfillment type, for example: Deliver now / Pickup now */
export const selectedOrderDayOptions = (
  selectedOrderDayMapping: OrderDayMapping,
  fulfillmentType: string
): OrderDayItems[] => [selectedOrderDayMapping[fulfillmentType]]

/** Checks stores for whether delivery or pickup is enabled */
export const isFulfillmentTypeEnabled = (
  stores: Store[],
  fulfillmentType: string
): boolean => {
  return !!hasValidStore(stores, fulfillmentType)
}

export const dropdownIsEditable = (values: TimeSlot[] | string[]): boolean =>
  values.length > 1

/** Gets total number of available dates, sums them & returns true if greater than 1 */
export const calendarIsEditable = (
  availableDates: AvailableDates,
  fulfillmentType: string
): boolean => {
  return (
    Object.keys(availableDates[fulfillmentType]).reduce(
      (acc, date) =>
        acc + [...new Set(availableDates[fulfillmentType][date])].length,
      0
    ) > 1
  )
}

/** Returns boolean based on whether there is a disabled order day */
export const orderDayFieldIsEditable = (
  selectedOrderDayMapping: OrderDayMapping,
  fulfillmentType: string
): boolean => {
  const findDisabledOrderDay = Object.keys(
    selectedOrderDayMapping[fulfillmentType]
  ).find((orderDay) => {
    return selectedOrderDayMapping[fulfillmentType][orderDay].disabled
  })
  return findDisabledOrderDay ? false : true
}

// Global fade out time in ms for modal/overlay
export const fadeInOutMs: number = 100

/** returns true if all provided stores are asap only */
export const storesAsapOnly = (stores: Store[]): boolean => {
  return stores.every((store) => store?.settings && store?.settings?.asap_only)
}

/** Gets full store details and returns true if any store has truthy asap_enabled value */
export const storesAsapEnabled = (
  stores: Store[],
  fulfillmentType: string,
  fulfillmentDate: Date
): boolean => {
  return stores.some((store) => {
    const splitHours =
      store.split_hours && formatSplitHours(store.split_hours, fulfillmentDate)

    const withinClosingTime = isDateTimeValidForClosing(
      store,
      splitHours,
      fulfillmentType,
      fulfillmentDate
    )

    return (
      (store?.settings && store?.settings.asap_enabled && withinClosingTime) ||
      false
    )
  })
}

/** Returns boolean for whether a given store is valid for preorder utilising getValidPreOrderStores */
export const storeIsValidForPreOrder = (
  currentStore: Store,
  fulfillmentType: string,
  currentLocation?: CurrentLocationState
) => {
  const { validStores: storeAvailableForPreOrder } = getValidPreorderStores(
    [currentStore],
    fulfillmentType,
    undefined,
    currentLocation
  )
  return storeAvailableForPreOrder.length > 0
}

/** Matches currentStore name against list of stores and returns full store object */
export const returnFullStore = (stores: Store[], currentStore: ValidStore) => {
  return stores.find((store) => store.name === currentStore.name)
}

export const isUKLocation = (location: CurrentLocationState) => {
  const addressComponent = location.addressComponents.find((cmp) =>
    cmp.types.includes('country')
  )
  return (
    addressComponent &&
    (addressComponent.short_name === 'UK' ||
      addressComponent.short_name === 'GB')
  )
}

/** Returns asap enabled store that is not the current store */
export const findSameDayAsapEnabledStore = (
  availableSameDayStores: Store[],
  fullCurrentStore: Store,
  fulfillmentType: string,
  fulfillmentDate: Date
) => {
  return availableSameDayStores.find((store) => {
    return (
      store.name !== fullCurrentStore.name &&
      storesAsapEnabled([store], fulfillmentType, fulfillmentDate) &&
      isAsapValidOperatingHours(fulfillmentType, [store])
    )
  })
}

/** Returns asap enabled store that is not asap only and not the current store */
export const findSameDayAsapEnabledNoneAsapOnlyStore = (
  availableSameDayStores: Store[],
  fullCurrentStore: Store,
  fulfillmentType: string,
  fulfillmentDate: Date
) => {
  return availableSameDayStores.find((store) => {
    return (
      store.name !== fullCurrentStore.name &&
      storesAsapEnabled([store], fulfillmentType, fulfillmentDate) &&
      !storesAsapOnly([store])
    )
  })
}

/** Checks if asap timeslot is valid for any provided stores array */
export const isAsapValidOperatingHours = (
  fulfillmentType: string,
  stores: Store[]
) => {
  return stores.find((store) => {
    return store.is_open && isASAPTimeSlotValid(fulfillmentType, store)
  })
}

/** Returns boolean if timeslot in timeslot list is same as currently selected timeslot */
export const isTimeslotSelected = (
  timeSlot: TimeSlot,
  fulfillmentTime: string,
  isExtendedHour: boolean
): boolean => {
  const { value, extended } = timeSlot
  if (value === fulfillmentTime && extended === isExtendedHour) {
    return true
  } else return false
}

/** Gets time slot range, show ASAP or set to lowercase & remove first instance of am or pm */
export const createReadableTimeSlotRangeInput = (
  timeSlots: TimeSlot[],
  fulfillmentTime: string | null,
  singleInputVersion: boolean
) => {
  const timeSlot = matchTimeSlotToFulfillmentTime(timeSlots, fulfillmentTime)

  // singleInputVersion is when only 1 timeslot is available & is shown in calendar input
  if (fulfillmentTime === 'immediately') {
    return 'ASAP'
  } else {
    const readableTimeslot = timeSlot?.range
      .toLowerCase()
      .replace(/(pm)|(am)/, '')
      .replace(/\s+/, ' ')
    if (singleInputVersion) return readableTimeslot?.replace(/-/, 'to')
    return readableTimeslot
  }
}

export const matchTimeSlotToFulfillmentTime = (
  timeSlots: TimeSlot[],
  fulfillmentTime: string | null
) => {
  return timeSlots.find((ts) => ts.value === fulfillmentTime)
}

/** Returns false if fulfillment time is before today */
export const isCartFulfillmentValid = (cart: ConsumerCart | null): boolean => {
  if (!cart) return false
  if (cart.fulfillment.orderType === ASAP_ORDER) return true

  if (cart.fulfillment.window.from) {
    const currentFulfillmentDate = parseISO(cart.fulfillment.window.from)
    if (
      currentFulfillmentDate.toISOString().split('T')[0] <
      new Date().toISOString().split('T')[0]
    ) {
      return false
    } else {
      return true
    }
  }
  return false
}

/** Returns current cart date if cart date is not in the past, otherwise returns default */
export const returnDefaultFulfillmentDate = (
  isEditMode: boolean,
  cart: ConsumerCart | null,
  currentCartDate: Date,
  defaultDate: Date
) => {
  if (isEditMode && isCartFulfillmentValid(cart)) {
    return currentCartDate
  }
  return defaultDate
}

/** Derives cart date from cart fulfillment.window.from (deliverBy) field or sets initial date with new Date()  */
export const createCartFulfillmentDate = (
  deliverBy: Date | null,
  isPreOrder: boolean | undefined
) => {
  if (!deliverBy) return new Date()
  // if deliverBy is not today and isPreOrder is false, timeslot was an extended hours, therefore set date to today
  if (isPreOrder === false && !isToday(deliverBy)) {
    return new Date()
  } else {
    return deliverBy
  }
}

export const trackFulfillmentTypeChange = (
  fulfillmentType: FulfillmentType,
  location: string
) => {
  trackGA4CustomFulfillmentTypeChanged(
    fulfillmentType,
    location,
    'slerpGA4Tracker'
  )
  trackGA4CustomFulfillmentTypeChanged(
    fulfillmentType,
    location,
    'merchantGA4Tracker'
  )
}

export const trackPostCodeChange = (location: string) => {
  trackGA4CustomPostCodeChanged(location, 'slerpGA4Tracker')
  trackGA4CustomPostCodeChanged(location, 'merchantGA4Tracker')
}

export const trackOrderDayChanged = (orderDay: string, location: string) => {
  trackGA4CustomOrderDayChanged(orderDay, location, 'slerpGA4Tracker')
  trackGA4CustomOrderDayChanged(orderDay, location, 'merchantGA4Tracker')
}

export const trackDateChanged = (location: string, date: Date) => {
  trackGA4CustomDateChanged(location, date, 'slerpGA4Tracker')
  trackGA4CustomDateChanged(location, date, 'merchantGA4Tracker')
}

export const trackStoreChanged = (store: ValidStore, location: string) => {
  const { id, name } = store
  trackGA4CustomStoreChanged(id, name, location, 'slerpGA4Tracker')
  trackGA4CustomStoreChanged(id, name, location, 'merchantGA4Tracker')
}

export const trackTimeslotChanged = (timeslot: string, location: string) => {
  trackGA4CustomTimeslotChanged(timeslot, location, 'slerpGA4Tracker')
  trackGA4CustomTimeslotChanged(timeslot, location, 'merchantGA4Tracker')
}

/**
 * PARTNER API -
 * Checks whether there are Delivery or Pickup values within orderFulfillmentTypes.
 * If at least one value, it is considered "enabled".
 */
export const getEnabledFulfillmentTypes = (
  orderFulfillmentTypes: OrderFulfillmentType[]
) => {
  let deliveryCount = 0
  let pickupCount = 0
  orderFulfillmentTypes.forEach((oft) => {
    if (oft.fulfillmentType === 'DELIVERY') deliveryCount += 1
    if (oft.fulfillmentType === 'PICKUP') pickupCount += 1
  })

  return {
    isDeliveryEnabled: deliveryCount > 0,
    isPickupEnabled: pickupCount > 0
  }
}
