import { AppliedModifier } from 'shop/components/Landing/types'
import { Decimal } from 'decimal.js'
import { addDays, isBefore, isPast, parseISO } from 'date-fns'
import { isNull } from 'lodash'
import {
  CartErrorMessage,
  CartProductError,
  CheckoutErrorCode,
  ConsumerCart,
  ConsumerCartError,
  ConsumerCartWarning,
  ExtendedOrderItem,
  Fulfillment,
  OrderItem
} from 'shop/types/cart'
import { isCartProductError } from '../Checkout'
import { ASAP_ORDER } from 'shop/types'
import {
  TrackableEvent,
  trackUserActionsFBPixel,
  trackUserActionsGA4
} from 'tracker'
import {
  merchantGA4EcommTrackAddToCart,
  merchantGA4EcommTrackRemoveFromCart,
  slerpGA4EcommTrackAddToCart,
  slerpGA4EcommTrackRemoveFromCart
} from 'tracker/GA/ecommerce'

type StoreVariant = {
  variant_id: string
  in_stock: boolean
  published_at?: string
  stock_count?: number
  stock_sold?: number
  stock_type?: string
}

export type Metadata = {
  pickup_type?: string
  recipient?: string
}

export const computeModifiersTotal = (modifiers: AppliedModifier[]) => {
  const total = modifiers.reduce((acc: Decimal, modifier: AppliedModifier) => {
    return acc.plus(new Decimal(modifier.amount))
  }, new Decimal(0))

  return total.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber()
}

export const isStoreSwitched = (slug: string, cart: ConsumerCart | null) => {
  if (cart) return cart.store.slug !== slug

  return false
}

/** Checks if expected delivery/pickup date is before current date (is expired),
 * if cart order type is asap then cart date is not expired,
 * otherwise compare 'from' in fulfillment window with current datetime */
export const isCartDateExpired = (fulfillment?: Fulfillment) =>
  !!fulfillment &&
  fulfillment.orderType !== ASAP_ORDER &&
  isBefore(new Date(fulfillment.window.from), new Date())

// should be a check if item is overbought
export const isItemAvailable = (
  storeVariant?: StoreVariant,
  quantityBought?: number
) => {
  if (!storeVariant) return false

  const bought = quantityBought || 0
  const stockSold = storeVariant.stock_sold || 0
  const stockCount = storeVariant.stock_count || 0
  const stockAvailable = Number(stockCount) - Number(stockSold)

  if (isNull(storeVariant.stock_type))
    return (
      storeVariant &&
      storeVariant.in_stock &&
      storeVariant.published_at !== null
    )

  return bought <= stockAvailable
}

export const checkMinimumOrderValue = (
  cartTotal: number,
  minimumOrderValue?: number | string | null
) => {
  if (!minimumOrderValue) return true

  return cartTotal >= sanitizeNumber(minimumOrderValue)
}

export const formatMinimumOrderValue = (
  minimumOrderValue?: string | number
) => {
  if (!minimumOrderValue) return ''

  return sanitizeNumber(minimumOrderValue).toFixed(2)
}

export const formatMoney = (price: number | string) => {
  return sanitizeNumber(price).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP'
  })
}

export const sanitizeNumber = (value?: string | number | null) => {
  if (!value) return Number(0)
  if (typeof value === 'string') return parseFloat(value)
  return value
}

export const isTableOrder = (metadata?: Metadata) => {
  if (!metadata) return false

  return metadata?.pickup_type === 'table'
}

export const getCurrentCartId = (domain: string) => {
  const cartDetails = localStorage.getItem(domain)

  if (!cartDetails) {
    return null
  }

  try {
    const parsedDetails = JSON.parse(cartDetails)

    const expiresAt = parsedDetails['expires_at']
    if (expiresAt && isPast(parseISO(expiresAt))) {
      localStorage.removeItem(domain)
      return null
    }

    return parsedDetails.cart_id || null
  } catch {
    localStorage.removeItem(domain)
    return null
  }
}

/** Checks whether user is logged in via hasura API */
export const isUserLoggedInViaHasura = (loggedIn: boolean): boolean => {
  // authVersion only exists if user logged in via consumer API
  return loggedIn && !localStorage.getItem('authVersion')
}

export const getAlcoholicItems = (orderItems: OrderItem[]) =>
  orderItems.filter((item: OrderItem) => item.restrictions.alcoholic)

/** Filters all product errors from the consumer cart errors array */
export const getCurrentProductErrors = (
  consumerCartErrors: ConsumerCartError[]
) => {
  return consumerCartErrors.filter((error) =>
    findErrorWarning([error], isCartProductError)
  ) as CartProductError[]
}

/** Takes warnings/errors array and finds error object using guard function */
export const findErrorWarning = (
  errorsOrWarnings: (ConsumerCartWarning | ConsumerCartError)[],
  errorCodeGuardFunc: (validation: CheckoutErrorCode) => boolean
): ConsumerCartWarning | ConsumerCartError | undefined => {
  return errorsOrWarnings.find((item) => {
    return errorCodeGuardFunc(item.message)
  })
}

/** Returns OrderItem/ExtendedOrderItem of invalid items when provided a cart & item ids */
export const getInvalidItems = (
  productError: CartProductError,
  orderItems: OrderItem[]
): (OrderItem | ExtendedOrderItem)[] => {
  const filterItemsByVariantId = (errorItems: { productVariantId: string }[]) =>
    orderItems.filter((item) =>
      errorItems.some(
        (errorItem) => item.variantId === errorItem.productVariantId
      )
    )

  const filterItemsById = (errorItems: { id: string }[]) =>
    orderItems.filter((item) =>
      errorItems.some((errorItem) => item.variantId === errorItem.id)
    )

  switch (productError.message) {
    case CartErrorMessage.INVALID_MODIFIERS:
      return filterItemsByVariantId(productError.breakdown || [])

    case CartErrorMessage.PRODUCTS_OUT_OF_STOCK:
    case CartErrorMessage.PRODUCTS_UNAVAILABLE:
      return filterItemsById(productError.variants || [])

    case CartErrorMessage.PRODUCT_PRICES_CHANGED:
      return orderItems.reduce(
        (acc, item) => {
          const matchedProductErrorInfo = productError?.priceComparison.find(
            (errorItem) => item.id === errorItem.orderItemId
          )
          return matchedProductErrorInfo
            ? [
                ...acc,
                {
                  ...item,
                  newPriceWithVat: matchedProductErrorInfo.newPriceWithVat,
                  oldPriceWithVat: matchedProductErrorInfo.oldPriceWithVat
                }
              ]
            : acc
        },
        [] as OrderItem[] | ExtendedOrderItem[]
      )
    default:
      return []
  }
}

export const saveCartToLocalStorage = (
  id: string,
  merchant: { slug: string; id?: string }
) =>
  localStorage.setItem(
    merchant.slug,
    JSON.stringify({
      cart_id: id,
      merchant_id: merchant.id,
      expires_at: addDays(new Date(), 1)
    })
  )

export const trackProductQuantityChange = (
  action: TrackableEvent,
  orderItem: OrderItem,
  storeName: string | undefined,
  merchantName: string | undefined
): void => {
  const { product, total, quantity } = orderItem
  const eventData = {
    product_id: product.id,
    name: product.name,
    currency: 'GBP',
    price: total.discounted || total.base,
    quantity: quantity
  }
  const body = {
    action,
    label: product.name,
    value: quantity
  }

  // eccommerce tracking
  const allQuantitiesPrice = parseFloat(total.base)
  const cartItemPrice = allQuantitiesPrice / orderItem.quantity

  const trackParams = {
    currency: 'GBP',
    // we can only ever shift the quantity by 1 each time.
    // so value is the price of the singular item changing in the cart.
    // variant price does not include modifiers, so take amount divided by quantity.
    value: cartItemPrice,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        affiliation: storeName || '',
        item_brand: merchantName || '',
        item_variant: product.name,
        price: cartItemPrice,
        // we can only ever shift the quantity by 1 each time.
        quantity: 1
      }
    ]
  }

  if (action === TrackableEvent.ProductAdded) {
    slerpGA4EcommTrackAddToCart(trackParams)
    merchantGA4EcommTrackAddToCart(trackParams)
  }
  if (action === TrackableEvent.ProductRemoved) {
    slerpGA4EcommTrackRemoveFromCart(trackParams)
    merchantGA4EcommTrackRemoveFromCart(trackParams)
  }

  trackUserActionsGA4(body, 'slerpGA4Tracking')

  // legacy tracking
  trackUserActionsFBPixel(action, eventData)
  trackUserActionsGA4(body, 'merchantGA4Tracking')
}
