import styled from '@emotion/styled'
import { IconTitle } from './IconTitle'
import { FaRegClock } from 'react-icons/fa'
import { useShop } from 'shop/hooks'
import { WorkTime } from './WorkTime'
import { timeToDatetime } from 'shop/utils'
import { capitalize } from 'lodash'

export const ShopSchedule = () => {
  const { currentPartnerStore } = useShop()
  const { openingHours } = currentPartnerStore || {}

  if (!openingHours?.length) {
    return <></>
  }

  const daysOfWeek = openingHours.map((openingHour, index) => {
    const closed = !openingHour.shifts.length

    const dayOfWeek = capitalize(openingHour.dayOfWeek)

    if (closed) {
      return <WorkTime key={index} day={dayOfWeek} closed />
    }

    const { shifts } = openingHour

    const times = shifts.reduce(
      (acc, shifts) => {
        const from = timeToDatetime(shifts.startTime)
        const to = timeToDatetime(shifts.endTime)

        return [...acc, { from, to }]
      },
      [] as { from: Date; to: Date }[]
    )

    return <WorkTime key={index} day={dayOfWeek} times={times} />
  })

  return (
    <Root data-testid='shop-schedule-root'>
      <IconTitle icon={<FaRegClock />}>Opening hours</IconTitle>
      <Schedule>{daysOfWeek}</Schedule>
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: '16px',
  width: '100%',
  maxWidth: '360px'
}))

const Schedule = styled.div(() => ({
  marginLeft: '24px',
  display: 'flex',
  flexFlow: 'column',
  gap: '12px'
}))
