import { useCallback, useEffect, useRef, useState, FC, RefObject } from 'react'
import styled from '@emotion/styled'
import { IoIosSearch } from 'react-icons/io'
import Logo from './Logo'
import { SearchBar } from '../Product'
import { useConsumerCart, useMediaQueries, useNavBar } from 'shop/hooks'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { Order } from 'shop/types/cart'
import { getFulfillmentFlags, getStoreUrl } from 'shop/utils'
import { fadeIn } from '../Shop/commonStyles'
import { PAGE_CHECKOUT, PAGE_SHOP, PageType } from './utils'
import { FulfillmentType } from 'shop/types'
import React from 'react'
import { CartActions } from '.'
import {
  DEFAULT_NAVBAR_HEIGHT,
  EXPANDED_NAVBAR_HEIGHT,
  navBarHeightTransition
} from './commonStyles'
import { isIOS, isMacOs, isSafari } from 'react-device-detect'

interface Props {
  transparent?: boolean
  page?: PageType
  order?: Order
  storeSlug?: string
}

interface LogoSectionProps {
  fulfillmentType: FulfillmentType | undefined
  page: PageType | undefined
  logoUrl: string
  slug: string | undefined
}

interface SearchProps {
  isShopSearchVisible: boolean
  searchBarRef: RefObject<HTMLInputElement>
}

/** Consumer Cart equivalent of the original navbar */
const NavBar = ({ transparent = false, page, order, storeSlug }: Props) => {
  const { cart } = useConsumerCart()
  const { logoUrl, navDetails } = useNavBar()
  const [isShopSearchVisible, setIsShopSearchVisible] = useState(false)
  const nav = useRef<HTMLElement | null>(null)
  const store = useRef<HTMLParagraphElement | null>(null)
  const searchBarRef = useRef<HTMLInputElement | null>(null)
  const { isMobile } = useMediaQueries()

  const orderStoreSlug = order && order.store.slug
  const slug = storeSlug || orderStoreSlug

  const fulfillmentType = cart?.fulfillment.type || order?.fulfillment.type

  useEffect(() => {
    if (transparent) window.onscroll = scrollHandler
  }, [transparent])

  const handleResize = useCallback(() => {
    if (isShopSearchVisible && window.innerWidth > 768) {
      setIsShopSearchVisible(false)
    }
  }, [isShopSearchVisible])

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleBlur()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleResize, handleKeyPress])

  const scrollHandler = () => {
    if (nav && nav.current && store && store.current) {
      if (document.documentElement.scrollTop > 10) {
        nav.current.classList.remove('-transparent')
        store.current.classList.add('-visible')
      } else {
        nav.current.classList.add('-transparent')
        store.current.classList.remove('-visible')
      }
    }
  }

  const handleSearchIconClick = () => {
    if (isShopSearchVisible) {
      if (!!searchBarRef.current?.value.length) {
        searchBarRef.current.value = ''
      }
      searchBarRef.current?.blur()
      setIsShopSearchVisible(false)
      return
    }
    setIsShopSearchVisible(true)

    const inputHelper = document.createElement('input')
    inputHelper.style.position = 'absolute'
    inputHelper.style.opacity = '0'

    nav.current?.appendChild(inputHelper)
    inputHelper.focus()

    setTimeout(() => {
      searchBarRef.current?.focus()
      nav.current?.removeChild(inputHelper)
    }, 100)
  }

  const handleBlur = () => {
    searchBarRef.current?.blur()
  }

  return (
    <Nav
      ref={nav}
      className={transparent ? '-transparent' : ''}
      id='nav-bar-component'
      data-testid='nav-bar-component'
      isShopSearchVisible={isShopSearchVisible}
    >
      <NavActionContainer>
        <Flexbox>
          <LogoSection
            page={page}
            logoUrl={logoUrl}
            fulfillmentType={fulfillmentType}
            slug={slug}
          />
        </Flexbox>
        {/* DESKTOP - Search Bar Input */}
        {!isMobile && (
          <Flexbox justify='flex-end'>
            {page === PAGE_SHOP && (
              <SearchBarSection
                isShopSearchVisible={isShopSearchVisible}
                searchBarRef={searchBarRef}
              />
            )}
          </Flexbox>
        )}
        <Flexbox justify='flex-end'>
          {/* MOBILE - Search Bar Icon toggle */}
          {page === PAGE_SHOP && isMobile && (
            <FadeInWrapper>
              <IconContainer
                id='nav-bar-search-icon'
                onClick={handleSearchIconClick}
              >
                <IoIosSearch color='#000' />
              </IconContainer>
            </FadeInWrapper>
          )}
          {page === PAGE_SHOP && (
            <FadeInWrapper>
              <CartActions merchantId={navDetails.merchantId} />
            </FadeInWrapper>
          )}
        </Flexbox>
      </NavActionContainer>
      {/* MOBILE - Search Bar Input */}
      {isMobile && page === PAGE_SHOP && (
        <SearchBarSection
          isShopSearchVisible={isShopSearchVisible}
          searchBarRef={searchBarRef}
        />
      )}
    </Nav>
  )
}

export default NavBar

const LogoSection: FC<LogoSectionProps> = React.memo(
  ({ fulfillmentType, page, logoUrl, slug }) => {
    const { isOrderAtTable } = getFulfillmentFlags(fulfillmentType)
    const navigateOnLogoClick = slug ? getStoreUrl(slug) : '/'
    const tableLandingPath = `/table/${slug}`

    const isCheckoutPage = page === PAGE_CHECKOUT

    return (
      <Flexbox isCheckout={isCheckoutPage}>
        <FadeInWrapper>
          <Logo
            imageUrl={logoUrl}
            url={
              isOrderAtTable
                ? tableLandingPath
                : slug
                  ? navigateOnLogoClick
                  : '/'
            }
          />
        </FadeInWrapper>
      </Flexbox>
    )
  }
)

const SearchBarSection: FC<SearchProps> = ({
  isShopSearchVisible,
  searchBarRef
}) => {
  return (
    <SearchBarWrapper isActive={isShopSearchVisible}>
      <SearchBar ref={searchBarRef} isShopSearchVisible={isShopSearchVisible} />
    </SearchBarWrapper>
  )
}

const FadeInWrapper = styled.div<
  StyledHTMLElement & { hidden?: boolean; minWidth?: string }
>(({ hidden = false }) => ({
  display: hidden ? 'none' : 'flex',
  animation: `${fadeIn} 0.5s ease-in-out forwards`,
  height: '100%',
  alignItems: 'center'
}))

const IconContainer = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    width: '32px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',

    '>svg': {
      width: '20px',
      height: '20px'
    },

    [theme.mediaQueries.viewport7]: {
      display: 'none'
    }
  })
)

const SearchBarWrapper = styled.div<
  StyledHTMLElement & { isActive: boolean },
  Required<Theme>
>(({ theme, isActive }) => ({
  width: '100%',
  margin: '0 auto',
  display: isActive ? 'block' : 'none',
  animation: `${fadeIn} 0.8s ease-in-out forwards`,

  [theme.mediaQueries.viewport7]: {
    animation: `${fadeIn} 0.5s ease-in-out forwards`,
    display: 'block',
    maxWidth: '560px',
    margin: '0 auto'
  }
}))

const Flexbox = styled.div<
  StyledHTMLElement & {
    justify?: 'space-between' | 'flex-end'
    isCheckout?: boolean
  },
  Required<Theme>
>(({ theme, justify = 'space-between', isCheckout = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isCheckout ? 'center' : justify,
  transition: 'all 100ms ease-in-out',
  width: '100%',
  gap: '12px',

  [theme.mediaQueries.viewport7]: {
    display: 'flex',
    justifyContent: justify
  }
}))

const NavActionContainer = styled.div(({ theme }: any) => ({
  display: 'flex',
  transition: 'all 100ms ease-in-out',
  fontWeight: theme.fonts['body'].weight,
  fontFamily: theme.fonts['body'].family,
  height: '36px',
  margin: '0 auto',
  maxWidth: '1920px',
  width: '100%',
  gap: '12px',
  [theme.mediaQueries.viewport2]: {
    padding: '0px 0px'
  },
  [theme.mediaQueries.viewport7]: {
    maxWidth: '1300px',
    padding: '0px 0px'
  },
  [theme.mediaQueries.viewport14]: {
    maxWidth: '1920px',
    padding: '0px 60px',
    columnGap: 'none'
  },
  [theme.mediaQueries.viewport16]: {
    padding: '0px 0px'
  }
}))

export const Nav = styled.nav<
  StyledHTMLElement & { isShopSearchVisible: boolean },
  Required<Theme>
>(({ theme, isShopSearchVisible }) => {
  // react-device-detect and the Chrome Dev tools emulation of an iPhone results in isSafari: true
  const isAppleSafari = (isMacOs || isIOS) && isSafari
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isShopSearchVisible ? '12px' : '0',
    fontWeight: theme.fonts['body'].weight,
    fontFamily: theme.fonts['body'].family,
    transition: navBarHeightTransition,
    padding: '16px',
    height: isShopSearchVisible
      ? `${EXPANDED_NAVBAR_HEIGHT}px`
      : `${DEFAULT_NAVBAR_HEIGHT}px`,
    color: '#fff',
    alignItems: 'center',
    position: theme.designMode
      ? 'unset'
      : isAppleSafari
        ? '-webkit-sticky !important'
        : 'sticky',
    right: 0,
    left: 0,
    top: 0,
    backgroundColor: theme.colors['primary'],

    backfaceVisibility: 'hidden', // force iOS GPU render
    WebkitBackfaceVisibility: 'hidden', // force iOS GPU render
    MozBackfaceVisibility: 'hidden', // force iOS GPU render
    transform: 'translate3d(0, 0, 0)', // force iOS GPU render
    WebkitTransform: 'translate3d(0, 0, 0)', // force iOS GPU render
    MozTransform: 'translate3d(0, 0, 0)', // force iOS GPU render

    [theme.mediaQueries.viewport7]: {
      flexDirection: 'unset',
      justifyContent: 'space-between'
    },
    [theme.mediaQueries.viewport16]: {
      padding: '0px 75px'
    },

    '&.-transparent': {
      backgroundColor:
        theme.components['banner'].height === 'none' ? 'auto' : 'transparent',
      position: theme.designMode ? 'unset' : 'fixed',
      [theme.mediaQueries.viewport7]: {
        justifyContent: 'center',
        position: theme.designMode
          ? 'unset'
          : isAppleSafari
            ? '-webkit-sticky'
            : 'sticky',
        backgroundColor: theme.colors['primary']
      }
    },
    zIndex: 20
  }
})
