import React, { useEffect, useState } from 'react'

import { useConsumerCart, useShop } from 'shop/hooks'
import { LoaderProps } from './types'
import { useHistory } from 'react-router-dom'
import { ConsumerCart } from 'shop/types/cart'
import { getCurrentCartId } from 'shop/components/Cart/utils'
import { ApolloError } from '@apollo/client'
import { checkHandleUnauthorizedError, returnToShop } from 'shop/utils/common'

export const ConsumerCartLoader = ({
  isParentLoaded = true,
  children
}: LoaderProps) => {
  const { currentStore, config } = useShop()
  const { domain } = config
  const history = useHistory()
  const {
    getConsumerCart,
    cart: consumerCart,
    validateCart,
    updateFulfillmentConsumerCart
  } = useConsumerCart()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState(false)
  const cartId = consumerCart?.id || getCurrentCartId(config.domain)

  useEffect(() => {
    if (!isParentLoaded) return
    const fetchData = async () => {
      if (!isLoaded && !inProgress && cartId) {
        return getConsumerCart()
          .then((res: ConsumerCart | null) => {
            // Validate cart when consumer cart load
            validateCart(false).then(() => {
              /** Temp Hack: Call update fulfillment to ensure cart has auto
               * discounts applied by BE on checkout page */
              updateFulfillmentConsumerCart({}).then(() => {
                setIsLoaded(!!res)
                setInProgress(false)
              })
            })
          })
          .catch((error: ApolloError) => {
            checkHandleUnauthorizedError(error, domain, currentStore, history)
            setInProgress(false)
            returnToShop(currentStore, history)
          })
      }

      // If no cart id is to be found then return to shop
      if (!isLoaded && !inProgress && !cartId) {
        returnToShop(currentStore, history)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentLoaded])

  return <React.Fragment>{children(isLoaded)}</React.Fragment>
}
