import styled from '@emotion/styled'
import { useEffect, useState, useMemo } from 'react'
import {
  QUERY_GET_ORDER,
  QUERY_GET_ORDER_BY_CART,
  MUTATE_CREATE_CUSTOMER_FROM_ORDER,
  SUBSCRIBE_TO_ORDER,
  getAbsintheSocketLink
} from 'shop/client'
import { NavBar } from 'shop/components'
import {
  useShop,
  useModal,
  useAccount,
  useRwg,
  useSessionStorage
} from 'shop/hooks'
import { Banner, OrderSummary } from 'shop/components/Order'
import Theme, { StyledHTMLElement } from 'shop/theme/types'
import { AccountCreationType } from 'shop/components/Order/types'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { thinScrollbar } from '../components/common'
import { usePageViewTracker } from 'tracker'
import { getAccountCreationType } from 'shop/components/Order/utils'
import { Order } from 'shop/types/cart'
import OrderAuthModal from 'shop/components/Order/OrderAuthModal'
import { NoteBreakdownItem } from 'shop/components/CartV2'
import { IoInformationOutline as InfoIcon } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import { OrderLoyalty } from 'shop/components/Order/LoyaltyV2'
import OrderPageLoader from 'shop/components/Loader/OrderPageLoader'
import ErrorModal from 'shop/components/Modal/ErrorModal'
import AccountCreationModal from 'shop/components/Order/AccountCreationModal'
import { CheckoutForm } from 'shop/components/Checkout/types'
import { enrichOrderEventData } from 'tracker/utils'
import { PAGE_ORDER } from 'shop/components/NavBar/utils'

interface ConfirmationPageProps {
  entityId?: string
  paymentId?: string
  mode: 'order' | 'cart'
}

const ConfirmationPage = ({
  entityId,
  mode,
  paymentId
}: ConfirmationPageProps) => {
  const { config, merchant, useShopClient } = useShop()
  const { isModalOpen, openModal, loginModal } = useModal()
  const history = useHistory()
  const location = useLocation()
  const { removeSessionStorageItem: removeCheckoutFormSessionStorage } =
    useSessionStorage<CheckoutForm>('temp-checkout-form-values')

  const { domain, apiKey } = config
  const client = useShopClient()
  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()

  // Handle query params on load
  useEffect(() => {
    // Keep token query param if present, otherwise remove stripe generated params
    const queryParam = new URLSearchParams(location.search)
    const token = queryParam.get('token')
    history.replace({
      pathname: location.pathname,
      search: token ? `?token=${token}` : ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Remove temp saved checkout form values on moving to tracking page
    removeCheckoutFormSessionStorage()
    // also remove any other session storage helpers
    sessionStorage.removeItem('hasSelectedTip')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [order, setOrder] = useState<Order | undefined>()
  const [orderAuthError, setOrderAuthError] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [websocketStatus, setWebsocketStatus] = useState<{
    loading: boolean
    hasInitialised: boolean
  }>({
    loading: false,
    hasInitialised: false
  })
  const [orderPollTimeout, setOrderPollTimeout] = useState<null | ReturnType<
    typeof setTimeout
  >>(null)

  const GET_ORDER_POLL_INTERVAL = 1300 // standard deviation of order creation is 1.3s

  const orderQueryArgs = {
    order: {
      query: QUERY_GET_ORDER,
      variables: { reference: entityId }
    },
    cart: {
      query: QUERY_GET_ORDER_BY_CART,
      variables: { reference: entityId }
    }
  }

  const {
    loading: getOrderLoading,
    stopPolling: stopGetOrderPoll,
    data: orderSuccess,
    error: orderErr
  } = useQuery(orderQueryArgs[mode].query, {
    variables: orderQueryArgs[mode].variables,
    context: {
      clientName: 'consumerApi'
    },
    pollInterval: GET_ORDER_POLL_INTERVAL,
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
    // onCompleted and onError integrations seem to ignore pollInterval.
  })

  useEffect(() => {
    // Set response data to be passed to child
    if (orderSuccess?.order) {
      stopGetOrderPoll()
      if (orderPollTimeout) clearTimeout(orderPollTimeout)

      setShowErrorModal(false)
      setOrder(orderSuccess.order)
    }
    // Set error bool & open login if user is unauthorised
    if (orderErr?.graphQLErrors[0]?.message) {
      // if new error from the poll, refresh the timeout below
      if (orderPollTimeout) clearTimeout(orderPollTimeout)

      const { message } = orderErr.graphQLErrors[0] || {}
      const lowerCaseMessage = message?.toLowerCase()
      if (
        lowerCaseMessage === 'unauthorized' ||
        lowerCaseMessage === 'auth_missing'
      ) {
        setOrderAuthError(true)
        !isLoggedIn && openModal('login')
        stopGetOrderPoll()
        return
      }

      // poll query back-off
      const timeout = setTimeout(() => {
        stopGetOrderPoll()
        setShowErrorModal(true)
      }, GET_ORDER_POLL_INTERVAL * 6) // 5 attempts and await the last

      setOrderPollTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSuccess, orderErr?.graphQLErrors[0]?.message])

  useEffect(() => {
    // only continue once the order data has come back
    if (!order?.reference) return

    const queryParam = new URLSearchParams(location.search)
    const token = queryParam.get('token')
    // fallback to query param token for auth
    const authToken = order?.trackingToken || token
    if (client && authToken) {
      setWebsocketStatus({
        loading: true,
        hasInitialised: false
      })
      const websocketLink = getAbsintheSocketLink(
        authToken,
        domain,
        apiKey,
        merchant.id || ''
      )
      client.setLink(websocketLink)

      setWebsocketStatus({
        loading: false,
        hasInitialised: true
      })
    }
  }, [client, order?.reference])

  // New subscription for order
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_TO_ORDER, {
    skip: !websocketStatus.hasInitialised
  })

  useEffect(() => {
    if (!subscriptionData) {
      return
    }

    const orderUpdate = subscriptionData?.order

    if (orderUpdate) {
      setOrder({ ...order, ...orderUpdate })
    }
    // adding order causes recursion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData])

  /** Remove used cart details from localStorage if order matches cartId in localStorage */
  useEffect(() => {
    if (order?.cartId && config.domain) {
      const merchantLocalStorageItem = localStorage.getItem(config.domain)
      if (merchantLocalStorageItem) {
        if (JSON.parse(merchantLocalStorageItem)?.cart_id === order?.cartId) {
          localStorage.removeItem(config.domain)
        }
      }
    }
  }, [order?.cartId, config.domain])

  /** Enrich any GA4 tracking data from order object. */
  useEffect(() => {
    if (order?.reference) {
      enrichOrderEventData(order)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.reference])

  return (
    <>
      <OrderHeaderArea>
        <NavBar page={PAGE_ORDER} order={order} />
      </OrderHeaderArea>
      {orderAuthError &&
        merchant?.id &&
        !isLoggedIn &&
        isModalOpen('login') &&
        loginModal({ merchantId: merchant.id })}

      {orderAuthError && isLoggedIn && !isModalOpen('login') && (
        <OrderAuthModal />
      )}

      {!orderAuthError && (
        <OrderPage
          order={order}
          getOrderLoading={getOrderLoading}
          showErrorModal={showErrorModal}
        />
      )}
    </>
  )
}

const OrderPage = ({
  order,
  getOrderLoading,
  showErrorModal
}: {
  order: Order | undefined
  paymentId?: string
  getOrderLoading: boolean
  showErrorModal: boolean
}) => {
  const { config } = useShop()
  const { domain } = config
  // Track page view
  usePageViewTracker()
  const { isUserLoggedIn } = useAccount()
  const isLoggedIn = isUserLoggedIn()
  const { dropoffNotes, customerDetails } = order || {}
  const history = useHistory()
  const [accountCreationTrigger, setAccountCreationTrigger] =
    useState<boolean>(false)
  const { email } = customerDetails || {}
  const [createCustomerFromOrder] = useMutation(
    MUTATE_CREATE_CUSTOMER_FROM_ORDER
  )
  const { getParsedRwgToken, getRwgTokenKey } = useRwg()

  useEffect(() => {
    const rwgToken = getParsedRwgToken()
    const rwgTokenKey = getRwgTokenKey()
    if (!rwgToken || !domain) return

    if (rwgToken) {
      const environment = process.env.REACT_APP_TEST_ENV || process.env.NODE_ENV
      const isDevEnv: boolean =
        !!process.env.REACT_APP_TEST_ENV ||
        ['test', 'development'].includes(environment)

      const endpoint = isDevEnv
        ? 'https://www.google.com/maps/conversion/debug/collect'
        : 'https://www.google.com/maps/conversion/collect'

      const rwgData = {
        conversion_partner_id: '20002578',
        rwg_token: rwgToken.token
      }

      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(rwgData)
      })
        .then((response) => response.json())
        .then(() => {
          localStorage.removeItem(rwgTokenKey)
        })
        .catch(() => {
          localStorage.removeItem(rwgTokenKey)
        })
    }
  }, [domain])

  useEffect(() => {
    const timer = setTimeout(() => {
      setAccountCreationTrigger(true)
    }, 6000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const closeAccountCreationModal = () => {
    setAccountCreationTrigger(false)
  }

  // Determine if we show account creation prompt & which version to show
  const merchantHasLoyaltyEnabled: boolean = useMemo(() => {
    const loyaltyCards = order?.loyalty.cards || []
    const hasActiveLoyalty = loyaltyCards.find(
      (card) =>
        card.stampsFromOrder.total > 0 || !!card.stampsFromOrder.isEarned
    )
    if (!!hasActiveLoyalty) return true
    return false
  }, [order?.loyalty?.cards])

  const accountCreationModalType: AccountCreationType = getAccountCreationType(
    merchantHasLoyaltyEnabled
  )

  const showAccountCreationModal: boolean = useMemo(() => {
    if (isLoggedIn || !accountCreationTrigger) return false
    if (!order?.isEligibleForAccountCreation) return false
    return true
  }, [isLoggedIn, accountCreationTrigger, order?.isEligibleForAccountCreation])

  /** Creates an account for user & sends email link to reset password */
  const sendAccountLinkToEmail = () => {
    createCustomerFromOrder({
      variables: {
        reference: order?.reference
      },
      context: { clientName: 'consumerApi' }
    }).catch((err) => {
      console.log(err)
    })
  }

  if (!!order?.reference && !getOrderLoading) {
    const { cards } = order.loyalty || {}

    // For 1st loyalty iteration, only show 1st relevant loyalty card
    const activeLoyaltyCards =
      cards.filter((card) => card.stampsFromOrder.total > 0) || []

    return (
      <OrderRoot data-testid='confirmationPage'>
        {showAccountCreationModal && email && (
          <AccountCreationModal
            type={accountCreationModalType}
            email={email}
            handleCloseModal={closeAccountCreationModal}
            handleCreateAccount={sendAccountLinkToEmail}
          />
        )}
        <OrderContentArea>
          <OrderBannerArea>
            <Banner order={order} />
            {!!dropoffNotes && (
              <DeliveryInstructionsContainer>
                <NoteBreakdownItem
                  Icon={InfoIcon}
                  noteTitle={'Delivery Instructions'}
                  note={dropoffNotes}
                />
              </DeliveryInstructionsContainer>
            )}
          </OrderBannerArea>

          {!!activeLoyaltyCards.length && isLoggedIn && (
            <OrderLoyaltyArea>
              {activeLoyaltyCards.map((loyaltyCard, index) => (
                <OrderLoyalty
                  key={loyaltyCard.id}
                  loyaltyCard={loyaltyCard}
                  isLast={index + 1 === activeLoyaltyCards.length}
                />
              ))}
            </OrderLoyaltyArea>
          )}

          <OrderSummary order={order} />

          <OrderActionArea>
            {/* Disable Order Again */}
            {/* <DeliveryActions
              order={order}
              orderV2={orderV2}
              isLoggedIn={isLoggedIn}
              paymentId={paymentId}
            ></DeliveryActions> */}
          </OrderActionArea>
        </OrderContentArea>
      </OrderRoot>
    )
  }

  return (
    <div>
      {getOrderLoading || !showErrorModal ? (
        <OrderPageLoader />
      ) : (
        <ErrorModal
          errorCode='ORDER_FAILURE'
          primaryButton={{
            message: 'Try Again',
            onClick: () => {
              history.push('/')
            }
          }}
        />
      )}
    </div>
  )
}

const OrderRoot = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateAreas: `
    'header'
    'content'
  `,

    [theme.mediaQueries.viewport12]: {
      paddingBottom: '0'
    }
  })
)

const OrderContentArea = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    gridArea: 'content / content',
    overflowY: 'scroll',

    width: '100%',
    paddingBottom: '0px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: `min-content`,
    justifyItems: 'center',
    gridTemplateAreas: `
    'banner'
    'loyalty'
    'summary'
    'info'
    'action'
  `,

    backgroundColor: '#f5f5f5',
    [theme.mediaQueries.viewport5]: {
      padding: '40px'
    },

    [theme.mediaQueries.viewport12]: {
      padding: '32px 32px 32px 64px',
      gridTemplateAreas: `
    'banner'
    'loyalty'
    'info'
    'summary'
    'action'
  `
    },

    ...(thinScrollbar(theme, 'none') as any)
  })
)

const OrderLoyaltyArea = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    gridArea: 'loyalty / loyalty',
    ...areaCardV2(theme, true, true, true),
    padding: '24px 16px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.mediaQueries.viewport7]: {
      ...areaCard(theme, false, true)
    },

    [theme.mediaQueries.viewport12]: {
      marginBottom: '24px',
      borderRadius: '12px'
    }
  })
)

const areaCard = (theme: Theme, hasPadding = true, hasMargin = true) => ({
  marginBottom: hasMargin ? '24px' : '0',
  padding: hasPadding ? '16px' : '0',
  overflow: 'hidden',
  width: '100%',
  '&:empty': {
    display: 'none'
  },

  [theme?.mediaQueries?.viewport7 || '']: {
    marginBottom: '24px',
    padding: hasPadding ? '32px' : '0',
    borderRadius: '12px',
    backgroundColor: '#fff',
    maxWidth: '664px'
  }
})

/** Temporary styling extending current areaCard styles for new layout/design of confirmation page */
const areaCardV2 = (
  theme: Theme,
  hasPadding = true,
  hasMargin = true,
  hasBorderRadius = false
) => ({
  ...areaCard(theme, hasPadding, hasMargin),
  marginBottom: hasMargin ? '12px' : '0',
  backgroundColor: '#fff',

  '> div > div': {
    borderRadius: hasBorderRadius ? '12px' : '0px'
  },

  [theme?.mediaQueries?.viewport5 || '']: {
    borderRadius: '12px',
    padding: hasPadding ? '32px' : '0',
    marginBottom: hasMargin ? '24px' : '0'
  }
})

const OrderActionArea = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    gridArea: 'action / action',
    ...areaCard(theme, false)
  })
)

const OrderBannerArea = styled.div<StyledHTMLElement, Required<Theme>>(
  ({ theme }) => ({
    gridArea: 'banner / banner',
    ...areaCardV2(theme, false, true, true),
    padding: '32px 16px 16px 16px',
    [theme?.mediaQueries?.viewport5 || '']: {
      padding: '32px 32px 18px',
      marginBottom: '24px',
      borderRadius: '12px'
    }
  })
)

const OrderHeaderArea = styled.div<StyledHTMLElement, Required<Theme>>(() => ({
  gridArea: 'header / header'
}))

const DeliveryInstructionsContainer = styled.div<StyledHTMLElement>(() => ({
  borderRadius: '12px'
}))

export default ConfirmationPage
